import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventsService } from 'src/app/service/events.service';
import { Event } from 'src/app/model/event';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { EventTopic } from 'src/app/model/eventTopic';
import { InfoService } from 'src/app/service/info.service';
import { NavbarService } from 'src/app/service/navbar.service';
import { UserService } from 'src/app/service/user.service';
import { AuthService } from 'src/app/service/auth.service';
import { NotificationService } from 'src/app/service/notification.service';
import { User } from 'src/app/model/user';
import { TopicInterest, TopicChoice, UserEventTopicsInterest } from 'src/app/model/user.event.topics.interest';
import { UserEventRole } from 'src/app/enum/user.event.role';

class CategoriesChoice extends TopicChoice {
  childrenInterests: Array<number> = [];
}

class TopicsChoice {
  topicID: number;
  interest: number;
}

@Component({
  selector: 'app-user-event-topics',
  templateUrl: './user-event-topics.component.html',
  styleUrls: ['./user-event-topics.component.scss']
})
export class UserEventTopicsComponent implements OnInit, OnChanges {
  @Input() user: User;

  public event: Event;

  public topics: Array<EventTopic> = [];
  public tableColumns = ['name', ...TopicInterest.keys()];
  public topicAnswerChoiceKeys = TopicInterest.keys();
  public topicAnswers = TopicInterest;

  public categoriesChoice: Array<CategoriesChoice> = [];

  public topicForm: FormArray;
  public selected;
  public loaded = false;
  private issu = false;

  public isComittee = false;
  public isAdminOrChair = false;

  public chairEditingPage = false;

  constructor(
    public route: ActivatedRoute,
    public eventsService: EventsService,
    private navbar: NavbarService,
    public router: Router,
    public fb: FormBuilder,
    public authService: AuthService,
    private userService: UserService,
    public infoService: InfoService,
    public notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.navbar.loadingStart();
    let userID: number = this.getUserIDFromRoute();
    if (userID == undefined) {
      this.userService.getUserFromToken().subscribe(u => {
        this.user = u;
      }, () => {
      },
      () => {
        this.initComponent();
      });
    } else {
      this.userService.getUser(userID).subscribe(user => {
        this.user = user;
      }, () => {},
      () => {
        this.initComponent();
      });
    }
  }

  ngOnChanges() {
    // Input changed.
    this.topicForm = undefined;
    this.topics = [];

    this.ngOnInit();
  }

  private initComponent(): void {
    setTimeout(() => {
      this.route.params.subscribe(({ eventId }) => {
        if (!eventId) {
          this.route.parent.params.subscribe(({ eventId: id }) => {
            this.initEvent(id);
          });
        } else {
          this.initEvent(eventId);
        }
      });
    });
    this.chairEditingPage = !!this.user;
  }

  private getUserIDFromRoute(): number {
    let userID: number;
    this.route.params.subscribe(params => userID = params.userId);
    return userID;
  }

  initEvent(eventId) {
    this.eventsService.getUserIsUserEvent(eventId, UserEventRole.COMMITTEE, this.user.id).subscribe(bool => {
      this.isComittee = bool;
    }, () => {},
    () => {
      this.authService.userIsAdminOrChair(eventId).subscribe(isAdminOrChair => {
        this.isAdminOrChair = isAdminOrChair;
      }, () => {},
      () => {
        if (this.isComittee == false && this.isAdminOrChair == false) {
          this.router.navigate(['/error/404']);
          this.navbar.loadingStop();
        }
        this.eventsService.getEvent(eventId).subscribe(event => {
          this.event = event;
          this.getUserSavedInterests();
          this.loaded = true;
          this.navbar.loadingStop();
        });
      });
    });
  }

  private getUserSavedInterests() {
    let choices: TopicsChoice[] = [];
    this.eventsService.getUserEventTopicsInterestByUserInEvent(this.event.id, this.user.id).subscribe({
      next: (topicsInterest) => {
        choices = topicsInterest.map(topicInterest => {
          return {
            topicID: topicInterest.eventTopic.id,
            interest: topicInterest.interest
          }
        });
        this.initForm(choices, topicsInterest);
    }});
}

  initForm(choices: TopicsChoice[], topicsInterest: UserEventTopicsInterest[]) {
    this.eventsService.getEventTopics(this.event.id).subscribe(topics => {
      this.categoriesChoice = topics.filter(t => t.isCategory).map(t => new CategoriesChoice(t));
      this.topics = topics.flatMap(c => [c, ...c.children]);

      // Reorder to uplist uncategorized topics
      const uncategorized: EventTopic[] = [];
      const categorized: EventTopic[] = [];
      this.topics.map(topic => {
        if (topic.children.length == 0 && topic.parent == null) {
          uncategorized.push(topic);
        } else {
          categorized.push(topic);
        }
      });
      this.topics = [...uncategorized, ...categorized];
      

      this.topicForm = this.fb.array(
        this.topics
          .map(topic => {
            const interest = choices.find(c => c.topicID === topic.id).interest;
            return new TopicChoice(topic, interest)
          })
          .map(topicChoice =>
            this.fb.group({
              eventTopic: [topicChoice.eventTopic],
              interest: [topicChoice.interest]
            })
          )
      );

      topicsInterest.forEach(topicInterest => {
        this.addInterestToCategoryChildrenInterest(topicInterest);
      });

    });
  }

  saveUserTopics(): void {
    const topicsInterest = this.topicForm.value.map(topicChoice => ({
      eventTopic: topicChoice.eventTopic.id,
      interest: topicChoice.interest
    }));

    this.eventsService.setUserEventTopicsInterest(this.user.id, this.event.id, topicsInterest).subscribe(
      response => {
        this.notificationService.notify('admin.event.topics.updated', { params: { name: this.event.name } });
      },
      error => {
        this.notificationService.notify('errors.topics-update-error');
      });
  }

  clearAllChoices(): void {
    for (const topicChoice of this.topicForm.controls as FormControl[]) {
      topicChoice.patchValue({ interest: TopicInterest.neutral });
    }
  }

  setUserSavedInterests() {
    this.eventsService.getUserEventTopicsInterestByUserInEvent(this.event.id, this.user.id).subscribe(topicsInterest => {
      const topicsChoice = this.topicForm.value;

      topicsInterest.forEach(interestTopic => {
        const choice = topicsChoice.find(c => c.eventTopic.id === interestTopic.eventTopic.id);

        if (choice) {
          choice.interest = interestTopic.interest;

          this.addInterestToCategoryChildrenInterest(choice);
        }
      });
      this.topicForm.patchValue(topicsChoice);
    });
  }

  addInterestToCategoryChildrenInterest(choice: TopicChoice) {
    if (choice.eventTopic.isSubtopic) {
      const parentId = typeof choice.eventTopic.parent === 'number' ? choice.eventTopic.parent : choice.eventTopic.parent.id;
      const parent = this.categoriesChoice.find(c => c.eventTopic.id === parentId);

      if (parent && !parent.childrenInterests.some(i => i === choice.interest)) {
        parent.childrenInterests.push(choice.interest);
      }
    }
  }

  selectAll(topicChoice: TopicChoice, interest: number) {
    if (topicChoice.eventTopic.isCategory) {
      const topicsChoice = this.topicForm.value;

      const categoryChoice = this.categoriesChoice.find(c => c.eventTopic.id === topicChoice.eventTopic.id);
      categoryChoice.interest = interest;
      categoryChoice.childrenInterests = [interest];

      topicsChoice.forEach(topic => {
        if (topic.eventTopic.parent === topicChoice.eventTopic.id) {
          topic.interest = interest;
        }
      });
      this.topicForm.patchValue(topicsChoice);
    }
  }

  interestStatus(topic: EventTopic, interest: number): 'fa-plus-square' | 'fa-minus-square' | 'fa-square' {
    const choice = this.findChoice(topic);
    if (!choice || !choice.eventTopic.isCategory) {
      return 'fa-square';
    }

    if (choice.childrenInterests?.length === 1 && choice.childrenInterests[0] === interest) {
      return 'fa-plus-square';
    } else if (choice.childrenInterests?.some(i => i === interest)) {
      return 'fa-minus-square';
    } else {
      return 'fa-square';
    }
  }

  findChoice(topic: EventTopic): CategoriesChoice {
    return this.categoriesChoice.find(t => t.eventTopic === topic);
  }

  reevalueteCategoryChildrenInterest(topicChoice: TopicChoice, interest: number) {
    const parentId = typeof topicChoice.eventTopic.parent === 'number' ? topicChoice.eventTopic.parent : topicChoice.eventTopic.parent?.id;

    if (parentId) {
      const childrenInterests = [interest];
      this.topicForm.value.forEach( topic => {
        if (topic.eventTopic.parent === parentId &&
            topic.eventTopic.id !== topicChoice.eventTopic.id &&
            !childrenInterests.some(i => i === topic.interest)) {
          childrenInterests.push(topic.interest);
        }
      });

      const parentChoice = this.categoriesChoice.find(t => t.eventTopic.id === parentId);
      if (parentChoice) {
        parentChoice.childrenInterests = childrenInterests;
      }
    }
  }
}
