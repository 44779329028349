import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { Submission } from 'src/app/model/paper';
import { Track } from '../../model/track';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/service/auth.service';
import { InfoService } from 'src/app/service/info.service';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { EventsService } from '../../service/events.service';
import { FileDownloadEvent, FilesService } from 'src/app/service/files.service';
import { NotificationService } from 'src/app/service/notification.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FileRules } from 'src/app/model/file.rules';
import { FileUploadViewDirective } from 'src/app/class/file-upload-view';
import { File } from '../../model/file';
import { CookieService } from 'ng2-cookies';
import { NavbarService } from 'src/app/service/navbar.service';
import { Subscription } from 'rxjs';
import { FileRulesCardInfoDialogComponent } from '../file-rules-card-info-dialog/file-rules-card-info-dialog.component';
import { Event } from 'src/app/model/event';
import { HttpResponse } from '@angular/common/http';
import { UPDATE_FILE_TYPE, TrackFileLoading } from '../paper-info/paper-info.component'; 
import { animate, style, transition, trigger } from '@angular/animations';
import { Dayjs } from 'dayjs';

export class RulesFileSlot {
  constructor(
    public rule: FileRules,
    public file: File = null,
    public error: string = null,
    public downloading: boolean = false
  ) { }
}

@Component({
  selector: 'app-file-rules-card',
  templateUrl: './file-rules-card.component.html',
  styleUrls: ['./file-rules-card.component.scss'],
  animations: [
    trigger(
      'inAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('0.3s ease-in-out', style({ opacity: 1 }))
          ]
        ),
      ]
    )
  ]
})
export class FileRulesCardComponent extends FileUploadViewDirective implements OnInit, OnChanges {
  fileDownloadPercent: number;

  rulesFileSlots: RulesFileSlot[];

  sessionId = false;
  subscriptions: Subscription[] = [];

  @Input() submission: Submission;
  @Input() event: Event;
  @Input() isReview: boolean;
  @Input() eventTimeZone: string;

  @Output() submissionChanged = new EventEmitter<Submission>();
  @Output() updateFileType = new EventEmitter<string>();

  @Input() userIsChair = false;
  public uploadError: string = null;

  @Input() handlerTrackFiles: Array<TrackFileLoading>;
  private handlerResetDragOnOver: { slotID: number, handlerTime: any, debounce: number } = { slotID: null, handlerTime: null, debounce: 100 };
  public disabledAnimation: boolean = true;

  constructor(
    private authService: AuthService,
    public fb: FormBuilder,
    public submissionsService: SubmissionsService,
    public eventsService: EventsService,
    public filesService: FilesService,
    public infoService: InfoService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    public cookieService: CookieService,
    private navbarService: NavbarService,
  ) {
    super(fb, submissionsService, filesService, eventsService, infoService);
  }

  ngOnInit() {
    this.initUploadForm();

    if (!this.userIsChair) this.setPermission();

    this.setTrackOfTrackFiles();

    this.rulesFileSlots = [];
    for (let rule of this.track.trackFiles) {
      if (this.showfile(rule)){
        let ruleFileSlot = new RulesFileSlot(rule);
        this.rulesFileSlots.push(ruleFileSlot);
        for (let file of this.submission.files) {
          if (file.trackFile.id == rule.id) {
            ruleFileSlot.file = file;
          }
        }
      }
    }
  }

  ngOnChanges() {
    this.disabledAnimation = true;
    this.ngOnInit();
  }

  private showfile(rule: FileRules):boolean {
    let user_id = this.infoService.user?.id;
    if (this.userIsChair || this.userIsAuthor) {
      return true;
    } else {
      if (this.submission.isSessionChair(user_id)) {
        return rule.visibleSessionChair;
      }
    }
    return true;
   }

  private setPermission() {
    this.authService.userIsAdminOrChair(this.event.id).subscribe(isChairOrAdmin => {
      this.userIsChair = isChairOrAdmin;
    });
  }

  fileUploaded(event: {slot: RulesFileSlot, status: number}, isComplete: boolean, isUpdate: boolean) {
    if (event.status === 100) {
      this.updateFileType.emit(UPDATE_FILE_TYPE.UPLOAD);
      event.slot.error = null;
      event.slot.rule.uploadStatus = undefined;
      const trackFiles = (<Track>this.submission.track).trackFiles;
      this.verifyAndUpdateStatus(this.submission, event.slot.rule, trackFiles, this.submissionChanged);
      this.handlerTrackFiles.find(trackFile => trackFile.id === event.slot.rule.id).loading = true;
      if (isComplete) {
        if (this.needSbcTemplate(event.slot)) {
          this.filesService.getFileBySubmissionTrackFile(this.submission.id, event.slot.rule.id).subscribe((file) => {
            if (!file.isUsingSbcTemplate)
              this.notificationService.notifyError("file-rules-view.file-not-using-sbc-template", { snackbarConfig: { panelClass: 'notification', horizontalPosition: 'left', verticalPosition: 'bottom',duration: 0 }})
            else if (isUpdate)
              this.notificationService.notify('file-rules-view.file-updated', { params: { id: this.submission.id } });       
          })
        } else if (isUpdate)
          this.notificationService.notify('file-rules-view.file-updated', { params: { id: this.submission.id } });
      }
    } else {
      event.slot.rule.uploadStatus = event.status;
    }
    this.handlerTrackFiles.find(trackFile => trackFile.id === event.slot.rule.id).statusUpload = event.status;
  }

  uploadErrorRaised(error: string) : void {
    this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true, autoFocus: false,
      data: {
        title: error,
        content: '',
        params: { onlyOkButton: true }
      }
    });
  }

  public handlerDragOver(slotID: number) {
    this.disabledAnimation = false;
    this.handlerTrackFiles.find(trackFile => trackFile.id === slotID).onOver = true;

    if (this.handlerResetDragOnOver.slotID === slotID) clearTimeout(this.handlerResetDragOnOver.handlerTime);
  }

  public resetDragOnOver(slotID: number) {
    this.handlerResetDragOnOver.slotID = slotID;
    this.handlerResetDragOnOver.handlerTime = setTimeout(() => {
      this.handlerTrackFiles.find(trackFile => trackFile.id === slotID).onOver = false;
    }, this.handlerResetDragOnOver.debounce);
  }

  public isDragOnOver(slotID: number): boolean {
    if (!this.handlerTrackFiles) return false;
    
    return this.handlerTrackFiles?.find(trackFile => trackFile.id === slotID).onOver;
  }

  public isUploading(slotID: number): string | boolean {
    if (!this.handlerTrackFiles) return false;

    const statusUpload = this.handlerTrackFiles.find(trackFile => trackFile.id === slotID).statusUpload;

    return statusUpload === 0 ? '100%' : `${statusUpload}%`;
  }

  public submitFile($event: {file: File, rule: FileRules, slot: RulesFileSlot}) {
    this.resetDragOnOver($event.slot.rule.id);
    const file = $event.file;
    const rules = $event.rule;
    const slot = $event.slot;

    const submitFile = {
      [rules.name]: [file]
    };

    const isUpdate: boolean = this.isUpdateFile(rules.name, this.submission.files);
    this.submissionsService.submitFiles(submitFile, this.submission.id, rules.track)
      .subscribe({
        next: statusList => {
          this.fileUploaded({slot, status: statusList[0].status}, false, isUpdate);
        },
        complete: () => {
          this.fileUploaded({slot, status: 100}, true, isUpdate);
        }
      });
  }

  private isUpdateFile(trackName: string, files: File[]): boolean {
    for (let file of files) {
      if (file.trackFile.name === trackName) {
        return true;
      }
    }
    return false;
  }

  setIcon(type: string) {
    switch (type) {
      case 'application/doc':
      case 'application/msword':
        return 'fa-file fa-file-doc';
      case 'application/pdf':
        return 'fa-file-pdf';
      case 'application/video':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'text/plain':
      case 'application/postscript':
      case 'text/html':
      case 'application/octet-stream':
        return 'fa-file';
      default:
        return 'fa-file-alt';
    }
  }

  downloadFile(rFile: RulesFileSlot) {
    const file: File = rFile.file;
    rFile.downloading = true;

    let downloadURL = file.url;
    // this.sessionId = this.cookieService.check('csrftoken');
    if (!this.sessionId) {
      // this.setSessionId();  // If to set sessionId cookie
      this.navbarService.loadingStart();
      let contentType = file.type ? file.type : 'application/pdf';
      // if (file.type) {
      //   let contentType = file.type;
      // }
      this.subscriptions.push(this.filesService.downloadFileWithProgress(file.id).subscribe((event : FileDownloadEvent) => {

        if (event.progress) {
          this.fileDownloadPercent = event.progress;
        }

        if(event.response instanceof HttpResponse){
          let blob = new Blob([event.response.body], { type: contentType });
          downloadURL = URL.createObjectURL(blob);
        }
      }, () => { },
        () => {
          this.navbarService.loadingStop();
          this.downloadLink(downloadURL, file.name);
          rFile.downloading = false;
        }));
      } else {
        this.downloadLink(downloadURL, file.name);
        rFile.downloading = false;
    }
  }

  downloadLink(downloadURL: string, fileName: string) {
    // Create link to download
    var link = document.createElement('a');
    link.href = downloadURL;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.download = fileName;
    link.click();
  }

  setSessionId() {
    this.authService.getSessionId().subscribe(
      response => {
        let sessionId = response.data;
        if (sessionId) {
          this.cookieService.set('sessionid', sessionId, 30, '/');
        }
      });
  }

  openDetailsDialog(slot: RulesFileSlot) {
    this.dialog.open(FileRulesCardInfoDialogComponent, { data: { rule: slot.rule } });
  }

  needSbcTemplate(slot: RulesFileSlot) {
    return slot.rule && slot.rule.useOfSbcTemplate;
  }

  isUsingSbcTemplate(slot: RulesFileSlot) {
    return slot.file && slot.file.isUsingSbcTemplate;
  }

  deleteFile(slot: RulesFileSlot) {
    this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true, autoFocus: false,
      data: {
        title: 'messages.confirm-file-deletion',
        content: ''
      }
    }).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.submissionsService.deleteSubmissionFile(slot.file, this.submission).subscribe(() => {
          this.updateFileType.emit(UPDATE_FILE_TYPE.DELETE);
          this.submissionsService.getSubmission(this.submission.id, true).subscribe(sub => {
            this.submissionSetEventAndTrack(sub, this.submissionChanged);
            this.submission = sub;
          });
        });
      }
    });
  }

  public setTrackFileLoading(trackFileID: number): boolean {
    if (!this.handlerTrackFiles) {
      return false;
    }
    return this.handlerTrackFiles.find(trackFile => trackFile.id === trackFileID).loading;
  }

  public hasDeadlinePassed(deadline: Dayjs): boolean {
    return deadline.toDate() < new Date();
  }

  public isExceedingPageLimit(slot: RulesFileSlot): boolean {
    if (!slot.file.pageCount || !slot.rule.maxPages) {
      return false;
    }

    return slot.file.pageCount > slot.rule.maxPages;
  }

}
