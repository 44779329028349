import { Component, OnInit, OnDestroy } from '@angular/core';
import { Submission, Review } from 'src/app/model/paper';
import { AuthService } from 'src/app/service/auth.service';
import { AdminService } from 'src/app/service/admin.service';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { ExportService } from 'src/app/service/export.service';
import { DEFAULT_PAPER_FIELDS_SHOW } from 'src/app/component/paper-card/paper-card.component';
import { Event } from '../../../../model/event';
import { ReviewsConfigurationService } from '../../../../service/reviews.configuration.service';
import { EventsService } from '../../../../service/events.service';
import { zip, Observable, Subscription, of, forkJoin } from 'rxjs';
import { SelectOption } from 'src/app/model/select.option';
import { Collection } from 'src/app/component/submissions-list-table/submissions-list-table.component';
import { FormControl, FormGroup } from '@angular/forms';
import { Track } from 'src/app/model/track';


@Component({
  selector: 'app-show-all-submissions',
  templateUrl: './show-all-submissions.component.html',
  styleUrls: ['./show-all-submissions.component.scss']
})
export class ShowAllSubmissionsComponent implements OnInit, OnDestroy {
  event: Event;
  submissions: Array<Submission>;
  fieldsShow = [...DEFAULT_PAPER_FIELDS_SHOW];

  subscriptions: Subscription[] = [];
  public downloadLink = false;

  public userIsChair = false;

  public paperGroupForm: FormGroup;
  public paperGroupList: Array<SelectOption>;
  private submissionGroupCollection: Array<Collection> = [];

  constructor(
    protected adminService: AdminService,
    public authService: AuthService,
    private submissionsService: SubmissionsService,
    private exportService: ExportService,
    private reviewConfigurationService: ReviewsConfigurationService,
    private eventsService: EventsService
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.adminService.progressBar.start();
      
      this.event = this.adminService.selectedEvent;
      if (!this.event) {
        this.adminService.getEvent().subscribe(event => {
          this.event = event;
          this.getPaperGroups();
          this.load();
        });
      } else {
        this.getPaperGroups();
        this.load();
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.adminService.progressBar.stop();
  }

  private getPaperGroups(): void {
    this.eventsService.getEventPaperGroups(this.event.id).subscribe(groups => {
      this.paperGroupList = groups.map(group => new SelectOption(group.id, group.name));
    });
  }

  load() {
    this.adminService.progressBar.start();

    // Cache event objects used in all submissions.
    this.subscriptions.push(forkJoin([
      this.authService.userIsAdminOrChair(this.event.id),
      this.reviewConfigurationService.getReviewsConfiguration(this.event.id),
      this.eventsService.getEventPaperGroups(this.event.id),
      this.eventsService.getFormReview(this.event.id),
      this.eventsService.getFormSubmission(this.event.id)
    ]).subscribe(([userIsChair, eventReviewConfiguration, submissionGroups, reviewForm, submissionForm]) => {
      this.subscriptions.push(this.submissionsService.getSubmissionsByEventFull(this.event.id, { detailed: true })
        .subscribe(submissions => {
          this.setPaperGroup(submissions);

          this.submissions = []; 
          this.submissions = submissions.map(s => {
            s.event = this.event;
            if(submissionForm) {
              s.formSubmission = submissionForm;
            }
            s.reviews = s.reviews.map(r => {
              if (r instanceof Review) {
                r.form = reviewForm;
                r.form.fields = r.form.fields.map(f => {
                  f.answers = r.answers?.filter(a => a?.formField === f.id);
                  return f;
                });
              }
              return r;
            });
            return s;
          });
          this.userIsChair = userIsChair;
        }, () => {},
        () => {
          this.setTrackInSubmissions();

          this.adminService.progressBar.stop();
        }));
    }));
  }

  private setTrackInSubmissions(): void {
    this.submissions.forEach(submission => {
      submission.track = this.event.tracks.find(track => submission.trackID === track.id);
      submission.files.forEach(file => {
        const trackFileID: number = (<Track>submission.track).trackFiles.find(trackFile => trackFile.name === file.trackFile.name)?.id;
        file.trackFile.id = trackFileID;
      });
    });
  }

  public setPaperGroup(submissions: Submission[]): void {
    this.paperGroupForm = new FormGroup({});

    submissions.forEach(submission => {
      const name: string = `paperGroup-${submission.id}`;
      this.paperGroupForm.addControl(name, new FormControl(submission.paperGroup))
      this.submissionGroupCollection.push({id: submission.id, name});
    });
  }

  regenerateExportHTML() {
    this.adminService.progressBar.start();
    this.generateExportHTML();
  }

  generateExportHTML() {
    this.subscriptions.push(this.exportService.extractExportHTML('showAll').subscribe(
      ({content, styles, links}) => {
        const html = `
          <!doctype html>
          <html>
            <head>
              <meta charset="utf-8">
              <meta name="viewport" content="width=device-width, initial-scale=1">
              <meta http-equiv="Content-Type" content="text/html;charset=UTF-8">

              <title>${this.event.name}</title>
              ${styles}
              ${links}
            </head>
            <body>
              <div class="show-all-submissions-container">
                <div class="show-all-submissions" id="showAll">
                  ${content}
                </div>
              </div>
            </body>
          </html>
        `;
        this.exportService.setDownloadTarget('saveHTML', `${this.event.name}.html`, html, 'text/html');
        this.adminService.progressBar.stop();
        this.downloadLink = true;
    }));
  }
}
