import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { FormGroup, Validators } from '@angular/forms';
import { NavbarService } from 'src/app/service/navbar.service';
import { NotificationService } from 'src/app/service/notification.service';
import { UserService } from 'src/app/service/user.service';
import { User } from 'src/app/model/user';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

export class DialogData {
  disabledNotification: boolean;
}

@Component({
  selector: 'app-dialog-create-user',
  templateUrl: './dialog-create-user.component.html',
  styleUrls: ['./dialog-create-user.component.scss']
})
export class DialogCreateUserComponent implements OnInit {
  public newUserForm: FormGroup;
  private userCreated: User;

  constructor(
    public dialogRef: MatDialogRef<DialogCreateUserComponent>,
    private fb: FormBuilder,
    public navbarService: NavbarService,
    private notificationService: NotificationService,
    private userService: UserService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.newUserForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.minLength(2)]],
      lastName: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [Validators.required, Validators.email]]
    });
  }

  public createUser(): void {
    if (this.newUserForm.valid) {
      this.navbarService.loadingStart();

      const fullName = `${this.newUserForm.value.firstName} ${this.newUserForm.value.lastName}`;

      if (!this.data?.disabledNotification) this.notificationCreatedUser(fullName);

      this.userService.createUserIgnoreErrorHandling(this.newUserForm.value).subscribe({
        next: (user) => {
          this.userCreated = user;
        },
        error: (e) => {
          if (e.error.status.params?.email === 'unique') {
            this.emailAlreadyExists();
          }
          this.navbarService.loadingStop();
        },
        complete: () => {
          this.navbarService.loadingStop();
          this.close(this.userCreated);
        }
      });
    }
  }

  private emailAlreadyExists(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.data = { 
      title: 'errors.could-not-create-user',
      content: 'errors.email-already-exists',
      params: { onlyOkButton: true }
    };

    this.dialog.open(ConfirmationDialogComponent, dialogConfig);
  }

  private notificationCreatedUser(fullName: string): void {
    const label = 'forms.creating-user';
    const params = { params: { user: fullName } };

    this.notificationService.notify(label, params);
  }

  public close(data = null): void {
    this.dialogRef.close(data);
  }

}
