import { Component, Input } from '@angular/core';
import { NotificationEmail } from 'src/app/model/notificationEmail';

@Component({
  selector: 'app-email-preview',
  templateUrl: './email-preview.component.html',
  styleUrls: ['./email-preview.component.scss'],
  providers: []
})

export class EmailPreviewComponent {
  @Input() preview: Array<NotificationEmail>;
  @Input() loading = false;

  constructor( ) { }
}
