import { ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit, ViewChild, Output, EventEmitter, Input, OnChanges, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { SystemService } from '../../service/system.service';
import { EventsService } from '../../service/events.service';
import { Workflow } from '../../model/workflow';
import { EventType } from '../../model/eventType';
import { AnonimityValues } from '../../enum/anonimity';
import { NotificationService } from 'src/app/service/notification.service';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Router } from '@angular/router';
import { EventRequest } from 'src/app/model/eventRequest';
import { EventRequestStatus } from 'src/app/enum/event.request.status';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/service/user.service';
import { TimezoneService } from 'src/app/service/timezone.service';
import { SelectOption } from 'src/app/model/select.option';
import { AdminService } from 'src/app/service/admin.service';
import { UserSearchComponent } from '../user-search/user-search.component';

@Component({
  selector: 'app-event-form',
  templateUrl: './event-form.component.html',
  styleUrls: ['./event-form.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ]
})
export class EventFormComponent implements OnInit, OnChanges {
  @ViewChild(UserSearchComponent) userSearch: UserSearchComponent;

  @Input() isEventRequest: boolean;
  @Input() selectedRequest: EventRequest;

  eventDataForm: FormGroup;

  @ViewChild(MatHorizontalStepper) public stepper: MatHorizontalStepper;
  stepperDefined = false;

  chipListSeparatorKeyCodes = [ENTER];
  anonimity = AnonimityValues;

  eventTypes: EventType[];
  workflows: Workflow[];

  errors: Array<{ control: string, errors: ValidationErrors }> = [];

  chairsID: any = [];

  minYear: number = 2000;

  allCities: any[] = [];
  countries: any[] = [];
  timeZones: any[] = [];

  eventParentList: Array<SelectOption> = [];

  TPCList: User[] = [];

  showCreateUserForm: boolean = false;

  @Output() submitOcurred: EventEmitter<{ form: FormGroup, errors: Array<{ control: string, errors: ValidationErrors }> }> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    public systemService: SystemService,
    public eventsService: EventsService,
    public notificationService: NotificationService,
    public dialog: MatDialog,
    public router: Router,
    private cd: ChangeDetectorRef,
    private userService: UserService,
    private timezoneService: TimezoneService,
    private adminService: AdminService
  ) { }

  ngOnInit() {
    this.initForm();

    this.ngOnChanges(); // execute at least once. ngOnChanges will be called every time input changes.
  }

  ngOnChanges() {
    setTimeout(() => {
      this.stepperDefined = this.stepper && this.stepper.steps && this.stepper.steps.length > 0;

      this.allCities = this.systemService.AllCities;
      this.timeZones = this.timezoneService.getAllTimeZones();
      this.getEventsWithoutParent();

      this.systemService.countries.subscribe(countries => {
        this.countries = countries;
      });

      if (this.selectedRequest) {
        this.eventDataForm.patchValue({
          name: this.selectedRequest.name,
          acronym: this.selectedRequest.acronym,
          year: this.selectedRequest.year,
          uri: this.selectedRequest.uri,
          eventType: this.selectedRequest.eventType,
          city: this.selectedRequest.city,
          state: this.selectedRequest.state,
          country: this.selectedRequest.country,
          timeZone: this.selectedRequest.timeZone,
          beginAt: this.selectedRequest.beginAt.toDate(),
          endAt: this.selectedRequest.endAt.toDate(),
          makePublicAt: this.selectedRequest.makePublicAt.toDate(),
          eventParent: this.selectedRequest.eventParent,
        });

        if (this.selectedRequest.chairs) {
          this.chairsID = (<string>this.selectedRequest.chairs).split(',').map(id => {
            return {id: Number(id)}
          });
        }

      }
    });
  }

  private initForm(): void {
    this.eventDataForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(150)]],
      acronym: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(30)]],
      year: ['', [Validators.required, Validators.min(this.minYear)]],
      uri: ['', [Validators.required], [this.eventsService.eventURIValidator()]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      country: ['', [Validators.required]],
      timeZone: ['', [Validators.required]],
      beginAt: ['', [Validators.required]],
      endAt: ['', [Validators.required]],
      makePublicAt: ['', [Validators.required]],
      eventParent: [''],
      anonimity: ['1'],
      chairs: ['']
    }, { updateOn: 'blur' });

    this.eventDataForm.get('acronym').valueChanges.subscribe(acronym => {
      const uri = this.eventDataForm.get('uri');
      const value = acronym.replace(/\s/g, '').toLowerCase();
      const year = this.eventDataForm.get('year').value;
      const uriValue = value.concat((year >= this.minYear) ? year : '');
      uri.setValue(uriValue);
    });

    this.eventDataForm.get('year').valueChanges.subscribe(year => {
      const uri = this.eventDataForm.get('uri');
      const value = (year >= this.minYear) ? year.toString(): '';
      const uriValue = this.eventDataForm.get('acronym').value.concat(value);
      uri.setValue(uriValue);
    });

    this.eventDataForm.get('uri').valueChanges.subscribe(uri => {
      this.eventDataForm.get('uri').setValue(uri.replace(/\s/g, '').toLowerCase(), { emitEvent: false });
    });
  }

  submitForm(): void {
    const title   = this.isEventRequest ? 'admin.event-requests.request-confirmation' : 'admin.event-requests.accept-confirmation';
    const content = this.isEventRequest ? 'admin.event-requests.alert-event-request' :'admin.event-requests.alert-send-notification';

    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: title,
        content: content
      }
    }).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.submitOcurred.emit({ form: this.eventDataForm, errors: this.errors });
      }
    });
  }

  reject(request: EventRequest) {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'admin.event-requests.decline-confirmation',
        content: 'admin.event-requests.alert-send-notification'
      }
    }).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        request.status = EventRequestStatus.REJECTED;
        this.eventsService.editEventRequest(request).subscribe(res => {
          this.notificationService.notify('admin.event-requests.rejected', { params: { event: request } });
          this.router.navigate(['/admin']);
        });
      }
    });
  }

  cancel() {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'admin.event-requests.cancel-confirmation',
        content: ''
      }
    }).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.router.navigate(['/admin']);
      }
    });
  }

  stepChanged($event) {
    if (this.stepper.steps.length - 1 === $event.selectedIndex) {
      this.errors = this.listFormGroupErrors(this.eventDataForm);
    }
  }

  next() {
    this.stepper.selectedIndex++;
  }

  back() {
    this.stepper.selectedIndex--;
  }

  listFormGroupErrors(form: FormGroup, controlParent = ''): Array<{ control: string, errors: ValidationErrors }> {
    const errors: Array<{ control: string, errors: ValidationErrors }> = [];

    for (const key in form.controls) {
      if (form.controls[key]) {
        const control = <FormGroup>form.controls[key];
        const controlName = controlParent.length > 0 ? `${controlParent}.${key}` : key;

        if (control.errors) {
          errors.push({ control: controlName, errors: control.errors });
        }

        if (control.controls) {
          errors.push(...this.listFormGroupErrors(control, controlName));
        }
      }
    }

    return errors;
  }

  errorValue(value: any): string {
    if (value instanceof Object) {
      const values = [];

      for (let atrib in value) {
        if (value[atrib] instanceof Object) {
          for (let a in value[atrib]){
            if (a == "min") {
              values.push(`min`);
            }
          }
        } else {
          if (value.hasOwnProperty(atrib)) {
            if (atrib == "required") {
              values.push(`required`);
            }
          }
        }
      }
      return values.join(', ');
    }

    return value;
  }

  public updatedSearchUser($event) {
    const { found, value } = $event;

    this.showCreateUserForm = !found && value;
  }

  public newUserAsChair($user: User): void {
    this.TPCList.push($user);
    this.showCreateUserForm = false;
    this.userSearch.addUserFromExternalComponent($user);
  }

  listChairs(values: any): string {
    const chairs = [];

    if (values.length > 0) {
      for(let i in values) {
        chairs.push(`${values[i]['firstName']} ${values[i]['lastName']}`);
      }
      return chairs.join(", ");
    } else {
      return "";
    }
  }

  removeEventRequest(): void {
    this.selectedRequest = null;

    this.cd.detectChanges();
    this.router.navigate(['/', 'admin']);
  }

  getEventTypeName(type: number): string {
    return this.eventTypes.find(id => Number(id) === type).name;
  }

  getFormSummary(form: FormGroup): any {
    const items: Array<{ name: string, control: string, data: any, error: any }> = [];

    for (let key in  form.controls) {
      let name: string = "";
      let control: string = "";
      let data: string = "";
      let error: any;
      let value = form.controls[key].value ? form.controls[key].value : null;

      switch (key) {
        case ('eventType'):
          control = "forms.fields.event-type";
          data = value ? ('eventTypes.' + this.eventTypes.find(type => type.id == value).name) : "";
          error = form.controls[key].errors ? this.errorValue(form.controls[key].errors) : null;
          items.push({ name: key, control: control, data: data, error: error});
          break;

        case ('anonimity'):
          control = "forms.fields.anonimity";
          data = value ? this.anonimity[Number(value)]["value"] : "";
          items.push({ name: key, control: control, data: data, error: null});
          break;

        case ('chairs'):
          control = "forms.fields.chairs";
          data = value ? this.listChairs(value): "";
          items.push({ name: key, control: control, data: data, error: null});
          break;

        default:
          control = `forms.fields.${key}`;

          if (key == "uri") {
            control = "forms.fields.event-uri";
          }

          data = value ? value: "";
          error = form.controls[key].errors ? this.errorValue(form.controls[key].errors) : null;
          items.push({name: key, control: control, data: data, error: error});
          break;
      }
    }
    return items;
  }

  private getEventsWithoutParent(): void {
    this.adminService.eventsService.getAllEventsWithoutParent()
    .subscribe(events => {
      next: {
        const options = events.map(event => new SelectOption(event.id, `${event.acronym} ${event.year} - ${event.name}`));
        this.eventParentList = options;
      }
    });
  }

  public getEventParentName(eventId?: number): string {
    return this.eventParentList.find(event => event.id === eventId)?.value;
  }

  public setOtherZoneForms($event: any): void {
    const stateCode = $event.state;
    const countryCodeAlpha2 = $event.country;
    const countryCodeAlpha3 = this.systemService.transformAlpha2ToAlpha3(countryCodeAlpha2);
    const nameCity = $event.city;
    const nameState = this.systemService.getNameStateByCodeAndCountry(stateCode, countryCodeAlpha2);
    const nameCountry = this.systemService.getNameCountryByCode(countryCodeAlpha2);

    this.setStateForm(stateCode);
    this.setCountryForm(countryCodeAlpha3);
    this.setTimeZoneForm(nameCity, nameState, nameCountry);
  }

  private setStateForm(stateCode: string): void {
    this.eventDataForm.controls.state.setValue(stateCode);
  }

  private setCountryForm(countryCodeAlpha3: string): void {
    const APICountry = this.countries.find(country => country.isoCode === countryCodeAlpha3).isoCode;
    this.eventDataForm.controls.country.setValue(APICountry);
  }

  private setTimeZoneForm(city: string, state: string, country: string): void {
    if (!!state && !!country) {
      const timezone = this.timezoneService.getTimeZone(city, state, country);
      this.eventDataForm.controls.timeZone.setValue(timezone);
    }
  }

  get url(): string {
    return window.location.origin;
  }
}
