export const STYLES_EXPORT_TABLE_V2 = 
`
    <style>
        * {
            font-family: 'open sans', sans-serif;
        }

        .paperBackground {
            border-radius: 5px;
            color: #363636;
        }

        .accepted {
            background-color: #99FF99;
        }

        .active {
            background-color: #FFFF55;
        }

        .rejected {
            background-color: #FFBDBD;
        }

        .pending {
            background-color: #FAB65C;
        }

        .withdrawn {
            background-color: #DDDDDD;
        }

        th {
            text-align: center;
			vertical-align: middle;
			padding: 0.5rem 1.5rem;
			font-size: 0.875rem;
			font-weight: 600;
			border: none;
            color: #0000008A;
        }

        th:last-child {
            display: none;
        }

        td {
            color: #363636;
			text-align: center;
			vertical-align: middle;
			padding: 0.5em;
			font-size: 0.813rem;
        }

        td:first-child {
            background-color: white;
        }

        td:nth-child(2) {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        td:nth-last-child(2) {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        .description {
            text-align: left;
        }

        .description .title {
            font-size: 1rem;
            font-weight: bold;
            text-align: left;
        }

        .description a {
            text-decoration: none;
        }

        .description a span {
            color: #0000EE;
            font-weight: 600;
        }

        .submission-topics {
            margin-top: 10px;
            margin-left: 0px;
        }

        .submission-topics .mat-chip-list-wrapper {
            display: flex;
            gap: 10px;
        }

        .submission-topics mat-chip {
            display: inline-flex;
            align-items: center;
            font-size: .75rem;
            font-weight: 400;
            margin-right: 1px;
            margin-top: 0px;
            padding: 0 10px;
            min-height: 1.4rem;
            white-space: nowrap;
            box-shadow: 0px 0px 1px rgba(0,0,0,.5);

            background-color: #ededed;
            color: #363636;
            border-radius: 10px;
        }

        .abstract {
            padding-top: .75rem;
            font-style: italic;
            text-align: justify;
            min-width: 500px;
        }

        .abstract span {
            font-weight: 700;
        }

        .rank-table, .files-table {
            display: inline-block;
            border-collapse: separate;
            border-spacing: 0px 1px;
        }

        .rank-table th, files-table th {
			white-space: pre-wrap;
			font-size: 0.75rem;
			font-weight: 400;
			vertical-align: bottom;
			color: rgba(0, 0, 0, .5);
			line-height: 1.2;
        }

        .rank-table th.file-icon-column {
            width: 100px;
        }

        .rank-table th:last-child {
            display: table-cell;
        }

        .rank-table td, files-table td {
            min-width: auto !important;
            height: 2em;
            padding: 2px 4px;
            border-top: 2px solid;
			border-bottom: 2px solid;
			border-left: none;
			border-color: transparent !important;
			background-color: #fff;
        }

        .rank-table td:first-child, .files-table td:first-child {
            background-color: #f8f8f8;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            border-left: 2px solid transparent;
        }

        .rank-table td:last-child, .files-table td:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            border-right: 2px solid transparent;
        }

        .rank-table td.uncompleted-review, .rank-table td:nth-child(n+19) {
            background-color: #f8f8f8;
        }

        .rank-table td.average {
            font-weight: 600;
        }

        .files-table th:last-child {
            display: table-cell;
        }

        .files-table td {
            background-color: #f8f8f8;
        }

        .vbar {
			width: 1px;
            min-width: 1px !important;
			background-color: transparent !important;
			padding: 0 !important;
		}

        .submission-form-item .field-title {
            font-weight: bold;
        }

        .submission-form-item .field-description {
            font-style: italic;
        }

        app-checkbox-input:has(input[aria-checked="false"]) {
            display: none;
        }

        .select {
            display: none;
        }

        .container-subtitle {
            display: flex;
            justify-content: flex-start;

            margin: 1em 0 0 3em;
        }

        .legend {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.5em;
        }

        .legend div span {
            font-weight: bold;
            padding: 0.25em;
            margin: 0 0.25em;
            min-width: 90px;
            display: inline-block;
            text-align: center;
        }

        .score-span {
            border-radius: 4px;
            padding: 3px;
        }

        .span-under-A {
            background-color: #75D775;
        }

        .span-above-B {
            background-color: #ff0000;
            font-weight: 700;
            color: rgb(255, 238, 238);
        }

        .span-between-A-and-B {
            background-color: #f0f04a;
        }

        app-menu-submission-list {
            display: none;
        }

        div[hidden] {
            display: none;
        }
    </style>
`;
