import { Component, OnInit, ViewChildren, QueryList, ViewChild, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { AdminService } from 'src/app/service/admin.service';
import { Submission, SubmissionStatus, Review } from 'src/app/model/paper';
import { Event } from 'src/app/model/event';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { SelectOption } from 'src/app/model/select.option';
import { EventTopic } from 'src/app/model/eventTopic';
import { ShowPaperCardsInterface } from 'src/app/interface/show-paper-cards-interface';
import { SubmissionFilter } from 'src/app/model/submission.filter';
import { SubmissionFieldAndFilter } from 'src/app/model/submissionFieldFilter';
import { SubmissionsListFilterComponent } from 'src/app/component/submissions-list-filter/submissions-list-filter.component';
import { forkJoin } from 'rxjs';
import { ReviewStatus } from 'src/app/enum/review.status';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { EventsService } from 'src/app/service/events.service';
import { NotificationService } from 'src/app/service/notification.service';
import { PaperGroup } from 'src/app/model/paper.group';
import { Form } from 'src/app/model/form';
import { FormField } from 'src/app/model/form.field';
import { FormFieldChoice } from 'src/app/model/form.field.choice';
import { ConfirmationDialogComponent } from 'src/app/component/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { plainToClass } from 'class-transformer';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ReviewsConfigurationService } from 'src/app/service/reviews.configuration.service';
import { EventReviewConfiguration } from 'src/app/model/eventReviewConfiguration';
import { ExportService } from 'src/app/service/export.service';
import { FilesService } from 'src/app/service/files.service';
import { PublicationService } from 'src/app/service/publication.service';
import { FormFieldCategory } from 'src/app/enum/form-field-category';
import { CheckListField } from 'src/app/model/checklist-field';
import * as _ from 'lodash';
import { MatDialogConfig } from '@angular/material/dialog';
import { SubmissionWithdrawalComponent } from '../submission-withdrawal/submission-withdrawal.component';
import { SubmissionDeleteComponent } from '../submission-delete/submission-delete.component';
import { File } from 'src/app/model/file';
import { FileRules } from 'src/app/model/file.rules';

export class RankedSubmission {
  rank?: Rank;
  submission: Submission;
  authorRegistration: boolean;
  copyright: boolean;
  selected = false;
}

class ByReviewRank {
  reviews: Array<{
    id: string,
    reviewer: { id: number, name: string },
    answers: Array<number>,
    average?: number,
    weight?: number,
  }>;
  labels: Array<string>;
  answers: Array<Array<number>>;
  average: Array<number>;
  weight: Array<number>;
}

class Rank {
  average: number;
  weightedAverage: number;
  span: number;
  globalWeight: number;

  byReview?: ByReviewRank;

  range?: {
    average: {
      high: number,
      low: number
    },
    weight: {
      high: number,
      low: number
    }
  };

  fields: Array<{
    [field: number]: {
      description: string,
      answers: number
    }
  }>;

  getReviewFieldAverage(fieldIndex: number): number {
    return this.byReview.answers.reduce((acc, reviewAnswers) => acc + reviewAnswers[fieldIndex], 0) / this.byReview.answers.length;
  }

  getReviewFieldWeight(): number {
    return this.byReview.weight.reduce((acc, reviewWeight) => acc + reviewWeight, 0) / this.byReview.weight.length;
  }

  getReviewFieldRange(fieldIndex: number): number {
    return -Math.max(...this.byReview.answers.map(reviewAnswers => reviewAnswers[fieldIndex]));
  }
}

export interface Collection {
  id: number;
  name: string;
}

@Component({
  selector: 'app-submissions-list-table[page]', // Declares page input is required
  templateUrl: './submissions-list-table.component.html',
  styleUrls: ['./submissions-list-table.component.scss']
})
export class SubmissionsListTableComponent implements OnInit, ShowPaperCardsInterface {

  @Input() page: string;

  SubmissionStatus = SubmissionStatus;

  event: Event;
  eventReviewConfiguration: EventReviewConfiguration;
  eventSubmissions: Submission[];
  rankedSubmissions: RankedSubmission[];
  reviewForm: Form;
  submissionForm: Form;

  form: FormGroup;

  paperGroupForm: FormGroup;
  clonePaperGroupForm: FormGroup;
  paperGroupList: Array<SelectOption>;

  customControlForm: FormGroup;

  statusForm: FormGroup;
  statusCollection: Array<Collection> = [];
  iconCollection: { [key: string]: { fontSet: string, fontIcon: string, color: string } } = {};
  status: Array<SelectOption> = SubmissionStatus.toSelectable();
  topics: Array<SelectOption>;
  tracks: Array<SelectOption>;
  submissionGroups: Array<SelectOption>;
  submissionGroupCollection: Array<Collection> = [];
  tpcGroups: Array<SelectOption>;

  filter = new SubmissionFilter();
  fieldsShow: string[];

  toggleMarkAll = false;
  panelOpenState = false;

  submissionsIdCoveredByAuthorRegistration: number[] = [];
  submissionsIdCoveredByCopyright: number[] = [];
  customCheckListFields: CheckListField[] = [];
  customCheckListSubmissions = {};
  registration:boolean;
  copyright:boolean;
  publicationFields = ['submissionId', 'status', 'title', 'topics', 'group', 'track', 'registration', 'crVersion'];
  defaultFilters = ['statusFilter', 'topicsFilter', 'tracksFilter', 'submissionGroupsFilter', 'tpcGroupsFilter'];
  defaultFields = ['submissionId', 'status', 'title', 'topics', 'abstract', 'name', 'affiliation', 'country', 'email', 'group', 'eventBool', 'track', 'rebuttal','discussionMessages', 'reviews', 'numericReviewScores', 'averageReviewScore', 'spanOfScores', 'reviewerName', 'files', 'submissionFormItems', 'registration', 'crVersion'];
  fieldsSaved = {};
  filtersSaved = {};
  tableRenderized = false;
  customCheckListFieldsSaved:string;

  convertColumnName = {
    'id': 'id',
    'title': 'title',
    'status':'status',
    'submission-track': 'track',
    'rebuttal':'rebuttal',
    'discussion-messages':'discussionMessages',
    'submission-group': 'paperGroup',
    'submission-reviewnumber': 'reviews',
    'score-span': 'span',
    'score-average-arithmetic': 'average',
    'score-average-weighted': 'weightedAverage',
    'author-registration': 'authorRegistration',
    'copyright': 'copyright'
  };

  @ViewChildren('submissionFilter') submissionFilters: QueryList<SubmissionsListFilterComponent>;

  @ViewChild(MatExpansionPanel) expansionPanel: MatExpansionPanel;

  loadingSubmissions = false;
  requestedShow = false;
  exportTableGenerated = false;

  tableColumns: string[];
  showExtraColumns: string[];
  tableDataSource: MatTableDataSource<RankedSubmission>;
  fieldsAndFilters: SubmissionFieldAndFilter;
  submissionTrackFilesMap: Map<number, Array<File>> = new Map();


  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    if (sort) {
      this.tableDataSource.sort = sort;
      this.tableDataSource.sortingDataAccessor = (item: RankedSubmission, header: string) => {
        switch (header) {
          case 'id': return item.submission.id;
          case 'title': return item.submission.title;
          case 'status': return item.submission.status;
          case 'submission-track': return item.submission.trackName;
          case 'rebuttal': return item.submission.rebuttal;
          case 'discussion-messages': return item.submission.discussionMessages.length;
          case 'submission-group': return this.paperGroupList.find(group => group.id === item.submission.paperGroup)?.value || 'Ω';
          case 'submission-reviewnumber': return item.submission.reviews.length;
          case 'author-registration': return item.authorRegistration;
          case 'copyright': return item.copyright;
          case 'score-span': return item.rank.span;
          case 'score-average-arithmetic': return item.rank.average;
          case 'score-average-weighted': return item.rank.weightedAverage;

          default: return this.customCheckListSubmissions[header.split('customField')[1]].includes(item.submission.id);
        }
      };
    }
  }

  constructor(
    public adminService: AdminService,
    public submissionsService: SubmissionsService,
    public eventsService: EventsService,
    public fb: FormBuilder,
    public notificationService: NotificationService,
    public dialog: MatDialog,
    public filesService: FilesService,
    public reviewConfigurationService: ReviewsConfigurationService,
    public exportService: ExportService,
    public publicationService: PublicationService,
  ) {
    this.iconCollection = {
      'ACCEPTED': {...SubmissionStatus.icon(SubmissionStatus.ACCEPTED), color: '#99FF99'},
      'ACTIVE': {...SubmissionStatus.icon(SubmissionStatus.ACTIVE), color: '#FFFF55'},
      'REJECTED': {...SubmissionStatus.icon(SubmissionStatus.REJECTED), color: '#FFBDBD'},
      'PENDING': {...SubmissionStatus.icon(SubmissionStatus.PENDING), color: '#FAB65C'},
      'WITHDRAWN': {...SubmissionStatus.icon(SubmissionStatus.WITHDRAWN), color: '#DDDDDD'},
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.adminService.progressBar.start();
      this.fieldsShow = ['roundBorderStart', 'id', 'status', 'title','submission-group', 'submission-event', 'submission-track', 'rebuttal', 'discussion-messages', 'submission-reviewnumber', 'score-numeric', 'score-average-arithmetic', 'score-average-weighted', 'score-span',  'others-files', 'others-form'];
    
      this.event = this.adminService.selectedEvent;
      if (!this.event) {
        this.adminService.getEvent().subscribe(event => {
          next: {
            this.event = event;
            this.initComponent();
          }
          complete: this.getPaperGroups(this.event.id);
        });
      } else {
        this.initComponent();
        this.getPaperGroups(this.event.id);
      }
    });

  }

  saveSortConfig():void {
    const sortBy = this.tableDataSource.sort.active;
    const reverseSort = this.tableDataSource.sort.direction === 'desc';
    this.eventsService.putSubmissionFieldsAndFilters(this.event.id, {sortBy: sortBy, reverseSort: reverseSort}).subscribe(() => {});
  }

  private getPaperGroups(eventID: number): void {
    this.eventsService.getEventPaperGroups(eventID)
      .subscribe(groups => {
        next: this.paperGroupList = groups.map(group => new SelectOption(group.id, group.name));
      });
  }

  getSubmissions() {
    this.requestedShow = true;
    if (this.expansionPanel) {
      this.expansionPanel.close();
    }
    this.submissionFilters.first.filter();
  }

  private getFieldsAndFilters() {
    this.eventsService.getSubmissionFieldsAndFilters(this.event.id, this.page).subscribe(FieldsAndFilters => {      
      this.fieldsAndFilters = FieldsAndFilters;
      this.defaultFields.forEach(field => {
        this.fieldsSaved[field] = FieldsAndFilters[field];
      });
      this.customCheckListFieldsSaved = FieldsAndFilters["customFields"];      

      this.defaultFilters = this.defaultFilters.map( (filter) => {
        const pos = filter.indexOf('Filter');
        const newFilterName = filter.substring(0, pos);
        this.filtersSaved[newFilterName] = FieldsAndFilters[filter];
        return newFilterName;
      });

      this.initFilters();

    });
  }

  private initFilters():void {
    this.defaultFilters.forEach(filter => {
      if (this.filtersSaved[filter]) {
        this.loadFilter(this.filtersSaved, filter);
      }
      this.listenToFilterChanges(filter);
    });
  }

  private loadFilter(filters:{}, filter:string):void {
    let f = filters[filter].trim().split(',');
    if (filter !== 'status') {
      f = f.map((element) => {return Number(element)});
    }
    this.form.controls[filter].setValue(f);
  }

  fieldsShowChanged() {
    this.tableColumns = this.fieldsShow.concat(["roundBorderEnd", "select"]);
    this.tableColumns.unshift('position');
  }

  initComponent() {
    this.tableDataSource = null;
    this.eventSubmissions = null;
    this.rankedSubmissions = null;
    const defaultStatus = this.page === 'publication' ? ['ACCEPTED'] : [];
    this.form = this.fb.group({
      status: [defaultStatus],
      topics: [],
      tracks: [],
      submissionGroups: [],
      tpcGroups: []
    });

    this.topics = this.event.topics
      .filter((t: EventTopic) => t.isParent)
      .map((t: EventTopic) => new SelectOption(t.id, t.name, undefined, t.children.map(t2 => new SelectOption(t2.id, t2.name))));

    if (this.topics.length === 0) {
      this.form.controls['topics'].disable();
    }

    this.tracks = this.event.tracks.map(t => {
      return new SelectOption(t.id, t.name);
    });

    if (this.tracks.length === 0) {
      this.form.controls['tracks'].disable();
    }

    forkJoin([
      this.adminService.eventsService.getEventPaperGroups(this.event.id),
      this.adminService.eventsService.getEventTPCGroups(this.event.id),
      this.reviewConfigurationService.getReviewsConfiguration(this.event.id),
      this.eventsService.getFormReview(this.event.id),
      this.eventsService.getFormSubmission(this.event.id),
      this.publicationService.getEventCheckListFields(this.event.id)
    ]).subscribe(([submissionGroups, tpcGroups, eventReviewConfiguration, reviewForm, submissionForm, checklistFields]) => {
      this.submissionGroups = submissionGroups.map(g => new SelectOption(g.id, g.name));
      if (this.submissionGroups.length === 0) {
        this.form.controls['submissionGroups'].disable();
      }

      this.tpcGroups = tpcGroups.map(g => new SelectOption(g.id, g.name));
      if (this.tpcGroups.length === 0) {
        this.form.controls['tpcGroups'].disable();
      }

      this.eventReviewConfiguration = eventReviewConfiguration;
      this.reviewForm = reviewForm;
      this.submissionForm = submissionForm;

      this.customCheckListFields = checklistFields;
      this.getSubmissions();
    });

    this.getFieldsAndFilters();
    this.initPublicationControls();
  }

  private listenToFilterChanges(filterName: string): void {
    this.form.controls[filterName]?.valueChanges.subscribe( (filterChanges) => {
      if (filterChanges) {
        if (filterChanges.length === 0) {
          filterChanges = null;
        } else {
          filterChanges = filterChanges.join(',');
        }

        if (! filterName.endsWith('Filter')) {
          filterName = filterName.concat('Filter');
        }

        let changes = {};
        changes[filterName] = filterChanges;

        this.eventsService.putSubmissionFieldsAndFilters(this.event.id, changes).subscribe(() => {});
      }
    });
  }

  initPublicationControls() {
    this.publicationService.getSubmissionsAnswers(this.event.id, {category: FormFieldCategory.CUSTOM}
      ).subscribe((submissionsCustomData) => {
        this.customCheckListSubmissions = submissionsCustomData;
      });
  }

  private initCustomControlForm(): void {    
    this.customControlForm = this.fb.group({});
    this.customCheckListFields.forEach(field => {
      this.eventSubmissions.forEach(submission => {
        const fieldId = field.id;
        const submissionId = submission.id;

        const controlName = `${fieldId}-${submissionId}`;
        const checkListValue = this.customCheckListSubmissions[fieldId].includes(submissionId);
        this.customControlForm.addControl(controlName, new FormControl(checkListValue));
      })
    });
  }

  private initSubmissionGroupForm(): void {
    this.paperGroupForm = new FormGroup({});
    this.eventSubmissions.forEach(submission => {
      const name = `paperGroup-${submission.id}`;
      this.paperGroupForm.addControl(name, new FormControl(submission.paperGroup));
      this.submissionGroupCollection.push({id: submission.id, name});
    });
    this.clonePaperGroupForm = _.cloneDeep(this.paperGroupForm);
  }

  private initStatusForm(): void {
    this.statusCollection = [];
    this.statusForm = new FormGroup({});
    this.eventSubmissions.forEach(submission => {
      const name = `status-${submission.id}`;
      this.statusForm.addControl(name, new FormControl(submission.status));
      this.statusCollection.push({id: submission.id, name});
    });
  }

  public submitStatusChange($status): void {
    const formGroupName: string = $status.source.ngControl.name;
    const submissionFormName: Collection = this.statusCollection.find(submission => submission.name === formGroupName);
    const submissionID: number = submissionFormName.id;

    if ($status.value === SubmissionStatus.WITHDRAWN) {
      const submission: Array<Submission> = this.eventSubmissions.filter(submission => submission.id === submissionID);
      let initialStatus: SubmissionStatus;
      this.submissionsService.getSubmission(submissionID, false).subscribe(submission => initialStatus = submission.status);
      this.changeSubmissionRowColor(submissionID, $status.value);
      this.openWithdrawDialog(submission, null, initialStatus);
      return;
    }
    this.submissionsService.editSubmission({status: $status.value}, submissionID)
      .subscribe(submission => {
        if (submission) this.messageSuccessChangeStatus($status.value);
      });

    this.changeSubmissionRowColor(submissionID, $status.value);
  }

  private changeSubmissionRowColor(submissionID: number, newStatus: SubmissionStatus): void {
    this.tableDataSource.data.find(item => item.submission.id === submissionID).submission.status = newStatus;
  }

  private messageSuccessChangeStatus(status: SubmissionStatus) {
    this.notificationService.notify(`reports.submissions.ranking.successful-assign-${status}`, { params: { total: 1 } });
  }

  updatePaperGroupList(options:Array<SelectOption>) {
    this.paperGroupList = options;
  }

  toggleCustomControlField(field: CheckListField, submission: Submission) {
    const data = {
      'checklist': field.id,
      'submission': submission.id
    };

    this.publicationService.toggleSubmissionAnswer(this.event.id, data).subscribe(newAnswer => {
      if (newAnswer) {
        this.customCheckListSubmissions[field.id].push(submission.id);
      } else {
        this.customCheckListSubmissions[field.id] = this.customCheckListSubmissions[field.id].filter(sId => sId != submission.id);
      }
    });

  }

  isCoveredBy(control: FormFieldCategory, submission: Submission) {
    if (control === FormFieldCategory.AUTHOR) {
      return this.submissionsIdCoveredByAuthorRegistration?.includes(submission.id);
    }

    if (control === FormFieldCategory.COPYRIGHT) {
      return this.submissionsIdCoveredByCopyright?.includes(submission.id);
    }

    return false;
  }

  getControlStatus(status: boolean) : string {
    return `admin.event.publication.status.${status ? 'checked' : 'unchecked'}`;
  }

  submissionsIsReady(rankedSubmission: RankedSubmission): boolean {
    let coveredByCustomControls = true;
    for (let id of this.customCheckListFields.map(c => c.id)) {
      if (!this.customCheckListSubmissions[id].includes(rankedSubmission.submission.id) && this.fieldsShow.includes(`customField${id.toString()}`)) {
        coveredByCustomControls = false;
        break;
      }
    }

    return (rankedSubmission.submission.status == SubmissionStatus.ACCEPTED) && (rankedSubmission.authorRegistration || !this.event.authorRegistrationEnable || !this.fieldsShow.includes('author-registration'))
    && (rankedSubmission.copyright || !this.event.copyrightEnable || !this.fieldsShow.includes('copyright'))
    && coveredByCustomControls;
  }

  getClass(rankedSubmission: RankedSubmission, name: string = null) {
    return {
      'ready': (this.submissionsIsReady(rankedSubmission) && this.page === 'publication'),
      'not-ready': (!this.submissionsIsReady(rankedSubmission) && this.page === 'publication'),
      'highlight': (rankedSubmission.selected && name != 'position')
    };
  }

  getCellClass(rankedSubmission: RankedSubmission, property: string) {
    return {
      'ready': (rankedSubmission[property] && this.page === 'publication'),
      'not-ready': (!rankedSubmission[property] && this.page === 'publication'),
      'highlight': rankedSubmission.selected
    };
  }


  getCustomCellClass(fieldId: number, submissionId: number, rankedSubmission: RankedSubmission) {
    const checkListIncludes = this.customCheckListSubmissions[fieldId].includes(submissionId);
    return {
      'ready': (checkListIncludes && this.page === 'publication'),
      'not-ready': (!checkListIncludes && this.page === 'publication'),
      'highlight': rankedSubmission.selected
    };
  }

  submissionsLength(status: 'ready' | 'not-ready') {
    let ready = 0;
    this.rankedSubmissions.forEach(rs => {
      if (this.submissionsIsReady(rs)) {
        ready++;
      }
    });

    return status == 'ready' ? ready : this.rankedSubmissions.length - ready;
  }


  load() {
    this.adminService.progressBar.start();
    this.loadingSubmissions = true;
    this.toggleMarkAll = false;

    if (!this.eventSubmissions) {
      this.submissionsService.getSubmissionsByEventFull(this.event.id, { detailed: true }).subscribe(submissions => {
        if (submissions.length === 0) {
          this.eventSubmissions = submissions;
          this.adminService.progressBar.stop();
          this.loadingSubmissions = false;
        }
        this.eventSubmissions = submissions;
      }, () => {}, () => {
        this.handleEventSubmissions();
      });
    } else {
      this.handleEventSubmissions();
    }
  }

  handleEventSubmissions() {
    // Filter eventSubmissions according to set filters
    const statusList: string[] = this.form.controls['status'].value;
    const topicList: number[] = this.form.controls['topics'].value;
    const trackList: number[] = this.form.controls['tracks'].value;
    const submissionGroupList: number[] = this.form.controls['submissionGroups'].value;
    const tpcGroupList: number[] = this.form.controls['tpcGroups'].value;

    if (this.eventSubmissions.length > 0) {
      const filteredSubmissions = this.eventSubmissions.filter(s => {
        if (statusList ?.length > 0 && !statusList.includes(s.status)) { // by Status
          this.adminService.progressBar.stop();
          this.loadingSubmissions = false;
          return false;
        }

        if (topicList ?.length > 0) {  // by Topics
          const hasFilteredTopic = s.topics.some(topic => topicList.includes(topic.id));

          if (!hasFilteredTopic) {
            this.adminService.progressBar.stop();
            this.loadingSubmissions = false;
            return false;
          }
        }

        if (trackList ?.length > 0) { // by Tracks
          const hasFilteredTrack = trackList.includes(s.trackID);

          if (!hasFilteredTrack) {
            this.adminService.progressBar.stop();
            this.loadingSubmissions = false;
            return false;
          }
        }

        // by Submission Group
        if (submissionGroupList ?.length > 0) {
          const inSubmissionGroupList = submissionGroupList.some(group => group === s.paperGroup);

          if (!inSubmissionGroupList) {
            this.adminService.progressBar.stop();
            this.loadingSubmissions = false;
            return false;
          }
        }

        // by TPC Group
        if (tpcGroupList ?.length > 0) {
          const inTPCgroupList = tpcGroupList.some(group => group === s.tpcGroup);

          if (!inTPCgroupList) {
            this.adminService.progressBar.stop();
            this.loadingSubmissions = false;
            return false;
          }
        }

        this.adminService.progressBar.stop();
        this.loadingSubmissions = false;
        return true;
      });

      this.rankedSubmissions = filteredSubmissions.map(submission => ({
        submission,
        rank: this.rank(submission),
        authorRegistration: submission.authorRegistration,
        copyright: submission.copyrightIEEE,
        selected: false
      }));


      this.initSort();

      this.tableDataSource = new MatTableDataSource<RankedSubmission>(this.rankedSubmissions);
      this.tableColumns = this.fieldsShow.concat(["roundBorderEnd", "select"]);
      this.tableColumns.unshift('position');         

      const foundScoreAverageColumn = this.tableColumns.findIndex(c => c === 'score-average');
      if (foundScoreAverageColumn >= 0) {
        const index = foundScoreAverageColumn; // Remove temporary column and insert real.
        this.tableColumns.splice(index, 1, ...['score-average-arithmetic', 'score-average-weighted']);
      }

      this.eventSubmissions.forEach(sub => 
        this.submissionTrackFilesMap.set(sub.id, this.allSubmissionTrackFiles(sub))
      );
    }
    
    this.initCustomControlForm();
    this.initSubmissionGroupForm();
    this.initStatusForm();

    // this.tableColumns.unshift('position');

    this.adminService.progressBar.stop();
    this.loadingSubmissions = false;
    this.tableRenderized = true;
  }

  private initSort(): void {    
    this.eventsService.getSubmissionFieldsAndFilters(this.event.id, this.page).subscribe(FieldsAndFilters => {
      const sortBy = FieldsAndFilters['sortBy'];
      const reverseSort = FieldsAndFilters['reverseSort'];
      this.tableDataSource.sort.active = sortBy;
      this.tableDataSource.sort.direction = reverseSort ? 'desc' : 'asc';
      this.sortRankedSubmissions(this.convertColumnName[sortBy], reverseSort);
    });
  }

  private sortRankedSubmissions(sortBy: string, reverse: boolean) {
    if ( sortBy === 'status' || sortBy === 'title' || sortBy === 'track' || sortBy === 'rebuttal' || sortBy === 'paperGroup' ) {
      this.sortRankedSubmissionsByAlfabeticalOrder(sortBy);
    }

    else if ( sortBy === 'discussionMessages' || sortBy === 'reviews' || sortBy === 'authorRegistration' || sortBy === 'copyright' || sortBy === 'span' || sortBy === 'average' || sortBy === 'weightedAverage') {
      this.sortRankedSubmissionsByNumericOrder(sortBy);
    }

    else {
      this.sortRankedSubmissionsByNumericOrder('id');
    }

    if ( reverse ) {
      this.rankedSubmissions = this.rankedSubmissions.reverse();
    }

  }

  private sortRankedSubmissionsByAlfabeticalOrder(column: string) {
    if (column === 'status' || column === 'title' || column === 'track' || column === 'rebuttal') {
      this.rankedSubmissions.sort((a,b) => {
        if (a.submission[`${column}`] && b.submission[`${column}`]) {
          if (a.submission[`${column}`] > b.submission[`${column}`]) {
            return 1;
          }
          else if (a.submission[`${column}`] < b.submission[`${column}`]) {
            return -1;
          }

          else {
            return a.submission.id - b.submission.id;
          }
        }
        return 0;
      });
    }

    else if (column === 'paperGroup') {
      this.rankedSubmissions.sort((a,b) => {
        const groupA = this.paperGroupList.find(group => group.id === a.submission.paperGroup);
        const groupB = this.paperGroupList.find(group => group.id === b.submission.paperGroup);
        if (groupA && groupB) {
          if (groupA.value > groupB.value) {
            return 1;
          }
          else if (groupA.value < groupB.value) {
            return -1;
          }

          else {
            return a.submission.id - b.submission.id;
          }
        }
        return 0;
      });
    }
  }

  private sortRankedSubmissionsByNumericOrder(column: string) {
    if ( column === 'discussionMessages' || column === 'reviews') {
      this.rankedSubmissions.sort((a,b) => {
        let difference = a.submission[`${column}`].length - b.submission[`${column}`].length;
        if ( difference === 0 ) {
          difference = a.submission.id - b.submission.id;
        }
        return difference;
      });
    }

    else if ( column === 'id') {
      this.rankedSubmissions.sort((a,b) => {
        return a.submission[`${column}`] - b.submission[`${column}`];
      });
    }

    else if (column === 'authorRegistration'|| column === 'copyright') {
      this.rankedSubmissions.sort((a,b) => {
        let difference = Number(a[`${column}`]) - Number(b[`${column}`]);
        if ( difference === 0 ) {
          difference = a.submission.id - b.submission.id;
        }
        return difference;
      });
    }
    else if (column === 'span' || column === 'average' || column === 'weightedAverage') {
      this.rankedSubmissions.sort((a,b) => {
        let difference = a.rank[`${column}`] - b.rank[`${column}`];
        if ( difference === 0 ) {
          difference = a.submission.id - b.submission.id;
        }
        return difference;
      });
    }

  }

  rank(submission: Submission): Rank {
    const completedReviews = submission.reviews.filter((r: Review) => r.status === ReviewStatus.COMPLETED);

    if (completedReviews.length === 0) {
      return plainToClass(Rank, { average: 0, weightedAverage: 0, span: 0, globalWeight: 0 });
    } else {
      const reviews = {};
      const resultsByReview = {};

      completedReviews.forEach((review: Review) => {
        reviews[review.id] = {};

        review.form = _.cloneDeep(this.reviewForm);

        review.form.assignableFields.forEach(field => {          
          const sortedChoices = field.choices.sort((c1, c2) => c2.value - c1.value);
          const answers = review.answers.filter(a => a.formField === field.id);          
          field.answers = answers;

          reviews[review.id][field.id] = {
            globalWeight: field.globalWeight,
            weight: field.weight,
            value: field.choices.find(c => c.id === (
              field.answers?.length > 0 ?  // TODO: Remove these commented lines. Workaround for field with no answers.
                field.answers[0].fieldChoice :
                sortedChoices[sortedChoices.length - 1].id // Field should always have answers, since the review is completed.
            )).value,
            maxValue: sortedChoices[0].value > 0 ? sortedChoices[0].value : 1,
            field,
            reviewer: { id: review.userId, name: review.userName }
          };
        });

        const fields: Array<FormField> = [];
        let sum_average = 0, sum_weight = 0, sum_global_weight = 0;

        let reviewerInfo;
        for (const fieldId in reviews[review.id]) {
          if (Object.prototype.hasOwnProperty.call(reviews[review.id], fieldId)) {
            const { globalWeight, weight, value, maxValue, field, reviewer }: {
              globalWeight: number,
              weight: number,
              value: number,
              maxValue: number,
              field: FormField,
              reviewer: { id: number, name: string }
            } = reviews[review.id][fieldId];

            sum_average += (value / maxValue * weight);
            sum_weight += (value / maxValue * globalWeight);
            sum_global_weight += globalWeight;
            fields.push(field);

            if (!reviewerInfo) {
              reviewerInfo = reviewer;
            }
          }
        }

        resultsByReview[review.id] = {
          average: sum_average * 10,
          weight: sum_weight,
          globalWeight: sum_global_weight,
          fields,
          reviewer: reviewerInfo
        };
      });

      const result = plainToClass(Rank, {
        average: 0,
        weightedAverage: 0,
        span: 0,
        globalWeight: 0,
        fields: [],
        byReview: {
          reviews: [],
          labels: [],
          average: [],
          weight: [],
          answers: []
        }
      });

      // Uncompleted Reviews
      const uncompletedReviews = submission.reviews.filter((r: Review) => r.status != ReviewStatus.COMPLETED);
      uncompletedReviews.forEach((review: Review) => {
        result.byReview.reviews.push({ id: review.id.toString(), reviewer: { id: review.userId, name: review.userName }, answers: [] });
      });

      let weightedDivider = 0;
      let lowestAverage, biggestAverage;
      let lowestWeight, biggestWeight;
      for (const review in resultsByReview) {
        if (Object.prototype.hasOwnProperty.call(resultsByReview, review)) {
          const { average, weight, globalWeight, fields, reviewer } = resultsByReview[review];

          result.average += average;
          result.weightedAverage += (average * weight);
          weightedDivider += weight;

          result.globalWeight += globalWeight;

          if (average > biggestAverage || biggestAverage === undefined) {
            biggestAverage = average;
          }
          if (average < lowestAverage || lowestAverage === undefined) {
            lowestAverage = average;
          }

          if (weight > biggestWeight || biggestWeight === undefined) {
            biggestWeight = weight;
          }
          if (weight < lowestWeight || lowestWeight === undefined) {
            lowestWeight = weight;
          }

          if (result.byReview.labels.length < 1) {
            result.byReview.labels = (<FormField[]>fields).map(f => f.shortLabel);
          }

          // Setup field choice answer accumulator
          if (result.fields.length === 0) {
            result.fields = (<FormField[]>fields).map(f => {
              const fieldChoices: {
                [field: number]: {
                  description: string,
                  answers: number
                }
              } = {};

              f.choices.forEach(choice => {
                fieldChoices[choice.id] = { description: choice.description, answers: 0 };
              });

              return fieldChoices;
            });
          }

          const answersValue = (<FormField[]>fields).map((field, fieldIndex) => {
            const answer = (<Array<FormFieldChoice>>field.answerForReview)[0];

            // Accumulate amount of answer of this field choice
            if (answer) {
              result.fields[fieldIndex][answer.id].answers += 1;
            } else {
              return null;
            }


            return answer.value;
          });

          result.byReview.reviews.push({ id: review, reviewer: reviewer, answers: answersValue, average: average, weight: weight });
          result.byReview.average.push(average);
          result.byReview.weight.push(weight);
          result.byReview.answers.push(answersValue);
        }
      }

      result.average /= Object.keys(resultsByReview).length;

      if (result.globalWeight === 0) {
        result.weightedAverage = result.average;
      } else {
        result.weightedAverage /= weightedDivider;
      }

      result.span = biggestAverage - lowestAverage;

      result.range = {
        average: {
          high: biggestAverage,
          low: lowestAverage
        },
        weight: {
          high: biggestWeight,
          low: lowestWeight
        }
      };

      return result;
    }
  }

  showColumn(item: RankedSubmission, type: 'topics' | 'authors-emails' | 'authors-affiliations' | 'submission-group'): string {
    switch (type) {
      case 'topics': return item.submission.topics.map(t => t.name).join(', ');
      case 'authors-emails': return item.submission.authors.map(a => a.user.email).join(', ');
      case 'authors-affiliations': return item.submission.authors.map(a => a.user.profile.affiliation.acronym).join(', ');
      case 'submission-group':
        if (item.submission.paperGroup instanceof PaperGroup) {
          return item.submission.paperGroup.name;
        }

        if (this.submissionGroups ?.length > 0 && item.submission.paperGroup) {
          return this.submissionGroups.find(group => group.id === item.submission.paperGroup).value;
        }
        return '';
      default:
        break;
    }
    return '';
  }

  selectSubmissions(status: SubmissionStatus) {
    const selected = this.rankedSubmissions.filter(s => s.selected);

    if (status === SubmissionStatus.WITHDRAWN) {
      const submissions: Array<Submission> = selected.map(s => s.submission);
      this.openWithdrawDialog(submissions, selected);
      return;
    }

    if (status === SubmissionStatus.DELETED) {
      const submissions: Array<Submission> = selected.map(s => s.submission);
      this.openDeleteDialog(submissions, selected);
      return;
    }

    const data = {
      title: `submissions.ranking.select-submission-${status}`,
      content: ''
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data });

    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {

        forkJoin(
          selected.map(s => {
            const submission = s.submission;
            submission.status = status;
            return this.submissionsService.editSubmission({ status }, submission.id);
          })
        ).subscribe((submissions: Submission[]) => {
          this.updateForm(selected);
          this.notificationService.notify(
            `reports.submissions.ranking.successful-assign-${status}`,
            { params: { total: submissions.length } }
          )
          });

      }
    });
  }

  private updateForm(selected: RankedSubmission[]): void {
    selected.forEach(s => s.selected = false);
    this.statusForm.reset();
    this.initStatusForm();
    this.toggleMarkAll = false;
  }

  private openWithdrawDialog(submissions: Array<Submission>, selected: any = null, statusInitial: SubmissionStatus = null): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = { submission: null , submissions: submissions };

    const dialogRef = this.dialog.open(SubmissionWithdrawalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(response => {
      if (response) {

        if (response.length > 0) {
          response.forEach((r: Submission) => submissions.find(s => s.id === r.id).status = SubmissionStatus.WITHDRAWN);
        } else {
          submissions.find(s => s.id === response.id).status = SubmissionStatus.WITHDRAWN;
        }

        if (selected) selected.forEach(s => s.selected = false);

        this.statusForm.reset();
        this.initStatusForm();
        this.toggleMarkAll = false;
      } else {
        if (statusInitial) {
          this.eventSubmissions.find(s => s.id === submissions[0].id).status = statusInitial;
          this.statusForm.reset();
          this.initStatusForm();
        }
      }
    });
  }

  private openDeleteDialog(submissions: Array<Submission>, selected: any = null, statusInitial: SubmissionStatus = null): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = { submission: null , submissions: submissions };

    const dialogRef = this.dialog.open(SubmissionDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(response => {
      if (response) {        
        this.initComponent();
      }
    });
  }

  legendValues(status: SubmissionStatus): number {
    return this.rankedSubmissions.filter(s => s.submission.status === status).length;
  }

  rankingSpanClass(item: RankedSubmission): string {
    if (item.rank.span < this.eventReviewConfiguration.spanLimitA) {
      return 'span-under-A';
    } else if (item.rank.span > this.eventReviewConfiguration.spanLimitB) {
      return 'span-above-B';
    }
    return 'span-between-A-and-B';
  }

  conflictLegendValues(conflict: 'span-under-A' | 'span-above-B' | 'span-between-A-and-B'): number {
    return this.rankedSubmissions.filter(item => {
      switch (conflict) {
        case 'span-under-A':
          return item.rank.span < this.eventReviewConfiguration.spanLimitA;
        case 'span-above-B':
          return item.rank.span > this.eventReviewConfiguration.spanLimitB;
        case 'span-between-A-and-B':
          return item.rank.span >= this.eventReviewConfiguration.spanLimitA && item.rank.span <= this.eventReviewConfiguration.spanLimitB;
      }
    }).length;
  }

  generateTableExport() {
    this.adminService.progressBar.start();
    this.exportTableGenerated = false;

    setTimeout(() => {
      this.exportService.extractExportHTML('rankedSubmissions').subscribe(({ content, styles, links }) => {
        const html = `
          <!doctype html>
          <html>
            <head>
              <meta charset="utf-8">
              <meta name="viewport" content="width=device-width, initial-scale=1">
              <meta http-equiv="Content-Type" content="text/html;charset=UTF-8">

              <title>${this.event.name}</title>
              ${styles}
              ${links}
            </head>
            <body>
              <div class="export-container">
                <div class="submission-ranking">
                  ${content}
                </div>
              </div>
            </body>
          </html>
        `;

        setTimeout(() => {
          this.exportService.setDownloadTarget('exportTable', `${this.event.name}.html`, html, 'text/html');
          this.exportTableGenerated = true;
          this.adminService.progressBar.stop();
        }, 1000);
      });

    }, 1000);
  }

  toggleSelectAll() {
    this.toggleMarkAll = !this.toggleMarkAll;
    this.rankedSubmissions.forEach(s => s.selected = this.toggleMarkAll);
  }

  allSubmissionTrackFiles(submission: Submission): Array<File> {
    let track = this.event.tracks.find(track => track.id === submission.trackID);
  
    if (submission.files.length === 0) {
      return track.trackFiles.map(trackFile => this.createNewFile(trackFile));
    }
  
    return this.getAllTrackFiles(submission, track.trackFiles);
  }
  
  private getAllTrackFiles(submission: Submission, trackFiles: Array<FileRules>): Array<File> {
    return trackFiles.map(trackFile => {
      let submissionFile = submission.files.find(file => file.trackFile.name === trackFile.name);
      return submissionFile ? submissionFile : this.createNewFile(trackFile);
    });
  }

  private createNewFile(trackFile): File {
    let file = new File(0, "", 0);
    file.trackFile = trackFile;
    return file;
  }

  isAMockFile(file: File): boolean {
    return !(file.file === "" && file.size === 0);
  }
}
