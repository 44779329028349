import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Dayjs } from 'dayjs';
import { AdminService } from '../service/admin.service';
import { InfoService } from '../service/info.service';
import { TimezoneService } from '../service/timezone.service';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'datePipe',
  pure: false,
})
export class DateFormatPipe implements PipeTransform {
  constructor(
    private translateService: TranslateService,
    private adminService: AdminService,
    private infoService: InfoService,
    private timezoneService: TimezoneService
    ){}

  public argToFormat(currentLang: string, args: string): any {
    let format: string;
    switch(currentLang) {
      // https://angular.io/api/common/DatePipe to add new options
      case ('pt-BR' || 'pt-br'): {
        switch(args) {
          case 'date': { format = "dd 'de' MMM yyyy"; break };
          case 'fullDate': { format = 'EEEE, d MMMM y'; break };
          case 'dateTime': { format =  "dd 'de' MMM yyyy HH:mm"; break };
          case 'dateTimeSecond': { format =  "dd 'de' MMM yyyy HH:mm:ss"; break };
          case 'dateTimeZone': { format =  "dd 'de' MMM yyyy HH:mm z"; break };
          case 'shortTime': { format = 'H:mm'; break };
          case 'shortTimeZone': { format = 'H:mm z'; break };
          default: { format =  "dd 'de' MMM yyyy"; break };
        }
        return format;
      }
      case 'en': {
        switch(args) {
          case 'date': { format = 'MMM dd, yyyy'; break };
          case 'fullDate': { format = 'EEEE, MMMM d, y'; break };
          case 'dateTime': { format = 'MMM dd, yyyy HH:mm'; break };
          case 'dateTimeSecond': { format = 'MMM dd, yyyy HH:mm:ss'; break };
          case 'dateTimeZone': { format =  'MMM dd, yyyy HH:mm z'; break };
          case 'shortTime': { format = 'h:mm a'; break };
          case 'shortTimeZone': { format = 'h:mm a z'; break };
          default: { format = 'MMM dd, yyyy'; break };
        }
        return format;
      }
    }
  }

  transform(value: Dayjs, args: string, timezone?: string): any {
    let currentLang;
    if(this.translateService.currentLang){
        currentLang = this.translateService.currentLang;
    }else{
         currentLang = "en";
    }
    const pipe = new DatePipe(currentLang);
    const dayjsToString = String(value);

    if (timezone !== undefined) {
      const timeZoneOffset: string = moment.tz(timezone).format('Z');

      return pipe.transform(dayjsToString, this.argToFormat(currentLang, args), timeZoneOffset); // return Timezone custom
    } else {
      return pipe.transform(dayjsToString, this.argToFormat(currentLang, args));
    }
  }
}
