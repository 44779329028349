import { Component, OnInit, ChangeDetectionStrategy, ViewChild, AfterViewInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { User } from '../../model/user';
import { TranslateService } from '@ngx-translate/core';
import { InfoService } from '../../service/info.service';
import { AuthService } from '../../service/auth.service';
import { ProfileTitle } from '../../enum/profile.title';
import { SelectOption } from '../../model/select.option';
import { SystemService } from '../../service/system.service';
import { ActingArea } from '../../enum/acting.area';
import { plainToClass } from 'class-transformer';
import { UserService } from '../../service/user.service';
import { NotificationService } from '../../service/notification.service';
import { LoginHistory } from 'src/app/model/login.history';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Affiliation } from 'src/app/model/affiliation';
import { CreateAffiliationComponent } from '../create-affiliation/create-affiliation.component';
import { Country } from 'src/app/model/country';
import { InputFilterAutocompleteComponent } from '../input-filter-autocomplete/input-filter-autocomplete.component';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileComponent implements OnInit, OnDestroy {

  constructor(
    public fb: FormBuilder,
    public translate: TranslateService,
    public userService: UserService,
    public systemService: SystemService,
    public authService: AuthService,
    private notificationService: NotificationService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.profileTitles = ProfileTitle.toSelectable();
    this.actingAreas = ActingArea.toSelectable();
  }
  profileForm: FormGroup;
  profileTitles: Array<SelectOption>;
  actingAreas: Array<SelectOption>;

  countryOption: Country;
  countryList: Array<Country>;

  private newAffiliationName: string;
  private newAffiliationAcronym: string;
  public allAffiliations: Affiliation[];
  public modelAffiliation = { dataBase: 'filter', value_1: 'filter' };
  private newAffiliationWasCreated: boolean = false;

  userSubmitForm: boolean = false;
  @Input() user: User;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(CreateAffiliationComponent) createAffiliation: CreateAffiliationComponent;
  @ViewChild(InputFilterAutocompleteComponent) inputFilterAutocomplete: InputFilterAutocompleteComponent;

  ngOnInit() {
    if (this.user) {
      this.initForm();
    } else {
      this.userService.getUserProfile().subscribe( user => {
        this.user = user;
      }, () => {},
      () => {
        this.initForm();
      });
    }
    this.initCountryList();
  }

  private initCountryList(): void {
    this.countryOption = new Country;
    this.countryOption.isoCode = this.user.profile.country;
    this.countryOption.name = this.user.profile.country;
    this.countryList = new Array;
    this.countryList.push(this.countryOption);
  }

  ngOnDestroy(): void {
    const affiliationOk: boolean = !!this.user.profile.affiliation.acronym;
    const countryOk: boolean = !!this.user.profile.country;

    if (!affiliationOk || !countryOk) {
      setTimeout(() => {
        this.router.navigate(['/settings']);
        this.showDialogMessage('messages.profile-incomplete');
      });
    }
  }

  showDialogMessage(message: string): void {
    this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: message
        }
      }).afterClosed().subscribe((confirmed) => {
          if (confirmed) this.router.navigate(['/settings']);
      });
  }

  public OpenDialogCreateAffiliation(params): void {
    if (params.value) {
      const dialogConfig = new MatDialogConfig();
  
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '30rem';
      dialogConfig.data = {
        affiliations: this.allAffiliations,
      }
  
      const dialogRef = this.dialog.open(CreateAffiliationComponent, dialogConfig);
  
      dialogRef.afterClosed().subscribe(
        (data) => {
          if (!!data) {
            this.newAffiliationWasCreated = true;
            this.newAffiliationName = data.name;
            this.newAffiliationAcronym = data.acronym;
            this.setNewAffiliationAfterCreate(this.newAffiliationAcronym, this.newAffiliationName);
          }
          this.blurInputAffiliation();
        }
      );
    }
  }

  private setNewAffiliationAfterCreate(acronym: string, name: string): void {
    const newAffiliation: string = this.buildStringAffiliation(acronym, name);
    this.profileForm.get('profile')['controls'].affiliation.setValue(newAffiliation);
  }

  private buildStringAffiliation(acronym: string, name: string): string {
    if (!!name && !!acronym) {
      return `${acronym}, ${name}`;
    } else if (!!name) {
      return name;
    } else if (!!acronym) {
      return acronym;
    } else {
      return '';
    }
  }

  public loadAffiliations(load: boolean): void {
    if (load) {
      this.systemService.getAllAffiliations().pipe(map(res => res.data))
        .subscribe((Affiliations: Affiliation[]) => this.allAffiliations = [...Affiliations]);
    }
  }

  public checkAffiliation(): void {    
    if (this.allAffiliations === undefined) {
      const affiliationID: number = this.profileForm.get('profile').value.affiliation?.id;
      this.profileForm.controls['profile'].value.affiliation = affiliationID;
      this.checkEmailDuplicate();
    } else {
      const affiliationForm: string = this.profileForm.get('profile').value.affiliation;
      let affiliationIsValid: boolean = false;

      for (let affiliation of this.allAffiliations) {
        if (affiliation.filter === affiliationForm) {
          this.profileForm.controls['profile'].value.affiliation = affiliation.id;
          affiliationIsValid = true;
          this.checkEmailDuplicate();
          break;
        }
      }

      if (!affiliationIsValid && !!this.newAffiliationWasCreated) {
        this.submitNewAffiliation();
      } else if (!affiliationIsValid) {
        this.showDialogMessage('messages.affiliation-form-invalid');
      }
    }
  }

  private submitNewAffiliation(): void {
    const name = this.newAffiliationName;
    const acronym = this.newAffiliationAcronym;
    const alternativeName = undefined;
    const domainEmail = undefined;

    this.userService.createAffiliation({ name, alternativeName, acronym, domainEmail })
    .subscribe({
      next: (data: Affiliation) => this.profileForm.controls['profile'].value.affiliation = data.id,
      complete: () => this.checkEmailDuplicate()
    });
  }

  public formIsValid(): boolean {
    return this.profileForm.valid;
  }

  public submit(): void {
    this.userService.update(plainToClass(User, this.profileForm.value as User)).subscribe(user => {
      this.checkEmailUpdated(user);
      this.userSubmitForm = true;
      this.user = Object.assign(this.user, user);
      this.notificationService.notify('profile.updated');
    });
  }

  private checkEmailDuplicate(): void {
    const email = this.profileForm.controls['email'].value;
    const firstAlternativeEmail = this.profileForm.controls['profile'].value.firstAlternativeEmail;
    const secondAlternativeEmail = this.profileForm.controls['profile'].value.secondAlternativeEmail;
    if(email == firstAlternativeEmail || email == secondAlternativeEmail || firstAlternativeEmail == secondAlternativeEmail) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = false;
      dialogConfig.data = { 
        title: 'profile.duplicate-email-dialog-title',
        content: 'profile.duplicate-email-dialog-content'
      };
      dialogConfig.data.params = { onlyOkButton: true };
      this.dialog.open(ConfirmationDialogComponent, dialogConfig); 
    } else {
      this.checkEmailChange();
    }
  }

  private checkEmailChange(): void {
    if (this.user.email !== this.profileForm.controls['email'].value) {
      this.dialog.open(ConfirmationDialogComponent, {disableClose: true, autoFocus: false,
        data: {
          title: 'profile.check-email-change-dialog-title',
          content: 'profile.check-email-change-dialog-content'
        }
      }).afterClosed().subscribe(confirmed => {
        if (confirmed) {
          this.submit();
        }
      });
    } else {
      this.submit();
    }
  }

  private checkEmailUpdated(user: User): void {
    if (user?.email !== this.user.email) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = false;
      dialogConfig.data = { 
          title: 'profile.updated-email-dialog-title',
          content: 'profile.updated-email-dialog-content',
          params: {
            content: {
              new_email: user.email
            },
            onlyOkButton: true
          }
      }
      this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(confirmed => {
        this.authService.signOut();
      });
    }
  }

  private initAffiliationForm(): String {
    let newAffiliationName: string | null = this.user.profile.affiliation.name;
    let acronymAffiliation: string | null = this.user.profile.affiliation._acronym;

    newAffiliationName === "undefined" ? newAffiliationName = null : newAffiliationName;
    acronymAffiliation === "undefined" ? acronymAffiliation = null : acronymAffiliation;

    return this.buildStringAffiliation(acronymAffiliation, newAffiliationName);
  }

  private initForm() {
    const affiliation = this.initAffiliationForm();
    const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    this.profileForm = this.fb.group({
      id: [this.user.id, [Validators.required]],
      firstName: [this.user.firstName, [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
      lastName: [this.user.lastName, [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
      email: [this.user.email, [Validators.required, Validators.email]],
      profile: this.fb.group({
        profileTitle: [this.user.profile.profileTitle],
        actingArea: [this.user.profile.actingArea],
        country: [this.user.profile.country, [Validators.required]],
        firstAlternativeEmail: [this.user.profile.firstAlternativeEmail, [Validators.email, Validators.maxLength(100)]],
        secondAlternativeEmail: [this.user.profile.secondAlternativeEmail, [Validators.email, Validators.maxLength(100)]],
        shortBio: [this.user.profile.shortBio, [Validators.maxLength(1000)]],
        publicPageURL: [this.user.profile.publicPageUrl, [
          Validators.pattern(urlRegex), Validators.maxLength(200)
        ]],
        phoneNumber: [this.user.profile.phoneNumber],
        affiliation: [affiliation, [Validators.required]],
        orcid: [this.user.profile.orcid, [
          Validators.pattern(/^([0-9]{4})?(-)?([0-9]{4})?(-)?([0-9]{4})?(-)?([0-9X]{4})$/), Validators.minLength(16), Validators.maxLength(19)
        ]],
        lattes: [this.user.profile.lattes, [
          Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.minLength(16), Validators.maxLength(16)
        ]]
      })
    });
  }

  private blurInputAffiliation(): void {
    this.inputFilterAutocomplete.input.nativeElement.blur();
    this.inputFilterAutocomplete.inputAutoComplete.closePanel();
  }
}
