import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Environment } from '../environment/environment';
import { HttpClient } from '@angular/common/http';
import { Response } from '../model/response';
import { Observable, throwError, of, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { InfoService } from './info.service';
import { User } from '../model/user';
import { UserEventRole } from '../enum/user.event.role';
import { LoginResponse } from '../model/login.response';
import { LoginHistory } from '../model/login.history';
import { plainToClass } from 'class-transformer';
import { EventsService } from './events.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private jwtHelper: JwtHelperService, private http: HttpClient, public router: Router, private infoService: InfoService,
    private eventsService: EventsService
  ) { }

  isAuthenticated(): boolean {
    let validToken = false;

    try {
      validToken = !this.jwtHelper.isTokenExpired(localStorage.getItem('token'));
    } catch (error) {
      validToken = false;
    }

    return validToken;
  }

  signOut(redirect = true): void {
    this.infoService.clearProxy();
    localStorage.clear();

    if (redirect) {
      this.router.navigateByUrl('/');
    }
  }

  signIn(data: { login: string, password: string }): Observable<LoginResponse> {
    const params: { email?: string, id?: number, password: string } = {
      password: data.password
    };

    const loginIsNumber = !isNaN(Number(data.login));
    if (loginIsNumber) {
      params.id = Number(data.login);
    } else {
      params.email = data.login;
    }

    return this.http.post<Response>(Environment.urls.API + '/core/auth/login/', params)
      .pipe(map((res: Response) => ({
        token: res.data.token,
        user: plainToClass(User, res.data.user as User)
      })));
  }

  refreshToken(data: { token: string }): Observable<Response> {
    return this.http.post<Response>(Environment.urls.API + '/core/auth/token/refresh/', data);
  }

  passwordReset(data: { uid: string, token: string, newPassword1: string, newPassword2: string }): Observable<object> {
    return this.http.post<object>(Environment.urls.API + '/core/auth/password/reset/confirm/', data);
  }

  requestPasswordReset(data: { login: string }): Observable<object> {
    const params: { email?: string, id?: number } = {};

    const loginIsNumber = !isNaN(Number(data.login));
    if (loginIsNumber) {
      params.id = Number(data.login);
    } else {
      params.email = data.login;
    }

    return this.http.post<object>(Environment.urls.API + '/core/auth/password/reset/', params);
  }

  passwordChange(data: { newPassword1: string, newPassword2: string, oldPassword: string }): Observable<Response> {
    return this.http.post<Response>(Environment.urls.API + '/core/auth/password/change/', data)
      .pipe(catchError((e: any) => throwError(e.error)));
  }

  userIsSuperuser(): any {
    let isAdmin = false;
    this.directUserIsAdmin().subscribe(issu => {
      isAdmin = issu;
    }, () => {
      return isAdmin;
    },
    () => {
      return isAdmin;
    });
  }

  userIsAdmin(): boolean {
    return this.userIsSuperuser();
  }

  userIsChair(event): Observable<boolean> {
    return this.eventsService.getEventChairs(event).pipe(
      map(userRoles => <boolean>!!userRoles.find(e =>
        e.user.id === this.infoService.user.id
      ))
    );
  }

  directUserIsAdmin(): Observable<boolean> {
    return this.http.get<Response>(`${Environment.urls.API}/core/token/validate/issu/`).pipe(map(bool => <boolean>bool.data)
    );
  }

  directUserIsChair(event: number): Observable<boolean> {
    return this.http.get<Response>(`${Environment.urls.API}/core/event/${event}/peopleIsChair/`).pipe(map(bool => <boolean>bool.data)
    );
  }

  directUserIsUserEvent(event: number, role: UserEventRole): Observable<boolean> {
    return this.http.get<Response>(`${Environment.urls.API}/core/event/${event}/userIsChairOrCommittee/`, {
      params: { role }
    }).pipe(map(bool => <boolean>bool.data)
    );
  }

  userIsAdminOrChair(event: number): Observable<boolean> {
    // Return True if user is Chair or SuperUser
    return this.directUserIsChair(event);
  }

  userIsCommittee(event: number): Observable<boolean> {
    return this.directUserIsUserEvent(event, UserEventRole.COMMITTEE);
  }

  userIsAdminOrChairOrCommittee(event: number): Observable<boolean> {
    return forkJoin([of(this.userIsAdmin()), this.directUserIsUserEvent(event, UserEventRole.CHAIR), this.directUserIsUserEvent(event, UserEventRole.COMMITTEE)])
      .pipe(map(
        ([isAdmin, isChair, isCommittee]) => isAdmin || isChair || isCommittee
      ));
  }

  getLoginHistory(user: number): Observable<Array<LoginHistory>> {
    return this.http
      .get<Response>(`${Environment.urls.API}/core/auth/user/loginHistory/${user}/`)
      .pipe(map(v => plainToClass(LoginHistory, v.data as Array<LoginHistory>)));
  }

  getSessionId(): Observable<Response> {
    return this.http
      .get<Response>(`${Environment.urls.API}/core/auth/user/sessionId/`);
  }

  hideEmail(email: string): string {
    return email?.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, "$1***@$2")
  }
}
