import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { ReviewsService } from 'src/app/service/reviews.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/component/confirmation-dialog/confirmation-dialog.component';
import { Submission, Review } from 'src/app/model/paper';
import { SubmissionStatus } from 'src/app/model/paper';
import { ReviewStatus } from 'src/app/enum/review.status';
import { InfoService } from 'src/app/service/info.service';
import { User } from 'src/app/model/user';
import { NotificationService } from 'src/app/service/notification.service';
import { ReviewsConfigurationService } from 'src/app/service/reviews.configuration.service';
import { EventReviewConfiguration } from 'src/app/model/eventReviewConfiguration';
import { EventsService } from 'src/app/service/events.service';
import { FormField, SortedFormFields } from '../../model/form.field';
import { FormFieldChoice  } from '../../model/form.field.choice';
import { Answer } from '../../model/answer';

export type PaperReviewListViewMode = 'full' | 'show-all-submissions';

@Component({
  selector: 'app-paper-review-list',
  templateUrl: './paper-review-list.component.html',
  styleUrls: ['./paper-review-list.component.scss']
})
export class PaperReviewListComponent implements OnInit, OnChanges {
  @Input()
  public element: Submission | Review;

  public reviewConfig: EventReviewConfiguration;

  public submission: Submission;
  public review: Review;

  @Input()
  public viewMode: PaperReviewListViewMode = 'full';

  @Input()
  public userIsChair = false;

  public reviews: Array<Review>;

  public eventId: number;

  public authorViewMode = true;

  public usedStatus: ReviewStatus[];

  @Output()
  public requestReviewRefresh: EventEmitter<{reviewId: number, delete?: boolean }> = new EventEmitter();

  constructor(
    private reviewService: ReviewsService,
    private dialog: MatDialog,
    private infoService: InfoService,
    private reviewConfigurationService: ReviewsConfigurationService,
    private notificationService: NotificationService,
    private eventsService: EventsService
  ) { }

  ngOnInit(){
    this.load();
  }

  load() {
    let isReviewID = false;

    if (this.element instanceof Submission) {
      this.submission = this.element;
      if (this.submission.reviews && this.submission.reviews.length > 0) {
        // Test if submission.reviews has review ID. Else, it has Reviews.
        isReviewID = !this.submission.reviews.some(isNaN);
      }
      if (!isReviewID) {
        this.reviews = <Array<Review>> this.submission.reviews;
      }

      if (!this.reviews) {
        this.reviewService.getReviewsBySubmissionId(this.submission.id).subscribe(reviews => {
          this.submission.reviews = reviews.map(r => {
            r.submission = this.submission;
            return r;
          });

          this.reviews = <Review[]> this.submission.reviews;
          this.defineEvent(this.submission);
          this.reviews.forEach(r => r.reviewQuestionVisibility = r.wasAnswered());
          this.elementsDefinedInit();
        });
      } else {
        this.defineEvent(this.submission);
        this.reviews.forEach(r => r.reviewQuestionVisibility = r.wasAnswered());
        this.elementsDefinedInit();
      }
    } else {
      this.review = this.element;
      this.reviews = [this.review];

      this.defineEvent(this.review.submission);
      this.reviews.forEach(r => r.reviewQuestionVisibility = r.wasAnswered());
    }

    setTimeout(() => {
      if (this.element instanceof Submission) {
        this.defineEvent(this.submission);
      }else{
        this.defineEvent(this.review.submission);
      }
      this.elementsDefinedInit();
      this.reviewConfigurationService.getReviewsConfiguration(this.eventId).subscribe(reviewConfig => {
        this.reviewConfig = reviewConfig;
      });
    });
  }

  ngOnChanges(changed) {
    if (changed.element) {
      this.submission = undefined;
      this.review = undefined;
      this.reviews = undefined;

      this.load();
    }

    if (changed.userIsChair) {
      this.elementsDefinedInit();
    }
  }

  public defineEvent(submission: Submission): void {
    this.eventsService.getEventByTrack(submission.trackID)
      .subscribe(e => this.eventId = e.id);
  }

  public elementsDefinedInit() {
    if (this.viewMode === 'show-all-submissions') {
      this.reviews.forEach(r => r.reviewQuestionVisibility = true);
    }

    this.authorViewMode = this.submission ? this.submission.isAuthor(this.infoService.user.id) : false;
    if (this.authorViewMode && this.userIsChair) {
      this.authorViewMode = false;
    }

    if (this.authorViewMode) {
      if (this.submission.status === SubmissionStatus.ACCEPTED || this.submission.status === SubmissionStatus.REJECTED) {
        this.reviews = this.reviews?.filter(r => r.status === ReviewStatus.COMPLETED);
      }
    }

    this.defineUsedStatus();
  }

  public defineUsedStatus() {
    if (this.reviews?.length > 0) {
      this.usedStatus = [...new Set(this.reviews.map(e => e.status))];
    }
  }

  public delete(review: Review): void {
    const id = review.id;

    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'messages.confirm-removed-review',
        content: ''
      }
    }).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.reviewService.deleteReview(id).subscribe(() => {
          this.notificationService.notify('messages.removed-user-review', { params: { user: review.userId } });
          this.reviews = this.reviews.filter(r => r.id !== id);

          this.requestReviewRefresh.emit({ reviewId: id, delete: true });
        });
      }
    });
  }

  public toggleReviewQuestions(review: Review): void {
    review.reviewQuestionVisibility = !review.reviewQuestionVisibility;
  }

  public userIsReviewer(review: Review): boolean {
    const currentUserId = this.infoService.user.id;
    let isReviewer = false;

    if (review.user instanceof User) {
      isReviewer = (review.user.id === currentUserId);
    }else{
      isReviewer = (review.user === currentUserId);
    }
    if (isReviewer == false){
      if (review.assignedBy instanceof User) {
        isReviewer = (review.assignedBy.id === currentUserId);
      }else{
        isReviewer = (review.assignedBy === currentUserId);
      }
    }
    return isReviewer;
  }

  public notify(review: Review) {
    this.reviewService.sendInvitationEmail([ review.id ]).subscribe(() => {
      this.notificationService.notify('reviews.notify', { params: { review: review.id }});

      this.requestReviewRefresh.emit({ reviewId: review.id });
    });
  }

  public updateReview(review: Review): void {
    this.requestReviewRefresh.emit({ reviewId: review.id });
  }

  public visibleFields(fields: (FormField[] | SortedFormFields[])): FormField[] | SortedFormFields[] {
    if (this.authorViewMode) {
      if (fields[0] instanceof FormField) {
        // Fields is FormField[]
        return (<FormField[]>fields).filter(f => !f.hidden);
      }

      // Fields is SortedFormFields[]
      const sortedFields = (<SortedFormFields[]>fields).map(sf => {
        sf.fields = sf.fields.filter(f => !f.hidden);
        return sf;
      });

      return sortedFields?.length > 0 ? sortedFields : [];
    }
    return fields;
  }

  // Change the selected choice's background
  public classAnswer(choice: FormFieldChoice, answerForReview: FormFieldChoice[]) {
    let filteredElements = answerForReview.find(a => a.id == choice.id)
    if (filteredElements) {
      return {
        'background-color': 'blue', // Change the selected choice's background
        'color': 'white',
        'font-weight': '700',
      }
    }
    return null;
  }
}
