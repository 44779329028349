import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../service/user.service';
import { LandingComponent } from '../landing.component';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  signUpForm: FormGroup;
  registrationCompleted: Boolean;

  constructor(
    private container: LandingComponent,
    private userService: UserService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.initForm();
  }

  submit() {
    if (this.signUpForm.valid) {
      this.container.progressBar.start();

      this.userService.create(this.signUpForm.value).subscribe(
        response => {
          this.registrationCompleted = true;
          this.container.progressBar.stop();
        },
        error => {
          if (error.error.status.params.email === 'unique') {
            this.signUpForm.controls['email'].setErrors({'unique': true});
          }
          this.container.progressBar.stop();
        }
      );
    }
  }

  private initForm() {
    this.signUpForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.minLength(2)]],
      lastName: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [Validators.required, Validators.email]]
    });
  }
}
