import { SelectOption } from '../model/select.option';

export enum ActingArea {
  STUDENT = 'profile.acting-area.student',
  ACADEMIA = 'profile.acting-area.academia',
  INDUSTRY = 'profile.acting-area.industry',
  GOVERNMENT = 'profile.acting-area.government'
}

export namespace ActingArea {
  export function toIterable(): Array<string> {
    return Object.keys(ActingArea).filter(key => typeof ActingArea[key] !== 'function');
  }

  export function toSelectable(): Array<SelectOption> {
    return ActingArea.toIterable().map((value, index) => ({
      id: value,
      value: ActingArea[value]
    }));
  }
}
