import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { Event } from 'src/app/model/event';
import { AdminService } from 'src/app/service/admin.service';
import { StatisticsService, AuthorCountries } from 'src/app/service/statistics.service';
import { SelectOption } from 'src/app/model/select.option';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-submission-tracks-statistics',
  templateUrl: './submission-tracks-statistics.component.html',
  styleUrls: ['./submission-tracks-statistics.component.scss']
})
export class SubmissionTracksStatisticsComponent implements OnInit {
  @Input() event: Event;

  form: FormGroup;
  tracks: Array<SelectOption>;
  options: any;
  trackStatus : Array<any>;
  dsTrackStatus : Array<any>;
  tableColsStatus = ['track', 'pending', 'active', 'withdrawn', 'accepted', 'rejected', 'count']

  constructor(
    private statisticsService: StatisticsService,
    protected adminService: AdminService,
    public fb: FormBuilder
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.adminService.progressBar.start();
      if (!this.event) {
        this.adminService.getEvent().subscribe(event => {
          this.event = event;
        }, () => {},
        () => {
          this.initComponent();
          this.getTrackBySubmissionStatus();
        });
      } else {
        this.initComponent();
        this.getTrackBySubmissionStatus();
      }
    });
  }

  initComponent(){
    this.form = this.fb.group({
      tracks: [],
      submissionGroups: []
    });

    this.tracks = this.event.tracks.map(t => {
      return new SelectOption(t.id, t.name);
    });
  }

  getTrackBySubmissionStatus(){
    this.statisticsService.getTracksBySubmissionStatus(this.event.id).subscribe( datasource => {
      this.trackStatus = datasource.items;
      this.dsTrackStatus = this.trackStatus;
      }, () => {
        this.adminService.progressBar.stop();
      },
      () => {
      this.adminService.progressBar.stop();
    });
  }

  getOptions(options) {
    if(this.form.value.tracks && this.form.value.tracks.length > 0) {
      this.dsTrackStatus = this.trackStatus.filter(track => this.form.value.tracks.includes(track.id));
    } else {
      this.dsTrackStatus = this.trackStatus;
    }
  }
}
