import { Injectable } from '@angular/core';
import { Environment } from '../environment/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { plainToClass } from 'class-transformer';
import { map } from 'rxjs/operators';
import { Response } from '../model/response';
import { ProceedingsContent } from '../model/proceedingsContent';
import { File, FileUploadErrors } from '../model/file';
import { CheckListAnswer, CheckListField } from '../model/checklist-field';
import { FileRules } from '../model/file.rules';
import { ProceedingsViewingOptions } from '../model/proceedingsViewingOptions';

class PublicationIdentificationInfo {
  id: number;
  isbn: string;
  issn: string;
}

export class PublicationEventInfo {
  id: number;
  copyrightEnable: boolean;
  authorRegistrationEnable: boolean;
  ieeeCopyrightEnable: boolean;
  ieeePubTitle: string;
  ieeeArtSource: string;
  ieeePartNumber: string;
}

export class SubmissionCoverage {
  field: CheckListField
  answer: boolean
}

@Injectable({
  providedIn: 'root'
})

export class PublicationService {

  constructor(
    private http: HttpClient
  ) { }

  getPublicationEventInfo(id: number): Observable<PublicationEventInfo> {
    return this.http.get<Response>(`${Environment.urls.API}/core/event/${id}/publication/`, {})
      .pipe(map(v => plainToClass(PublicationEventInfo, v.data as PublicationEventInfo)));
  }

  editPublicationControls(id: number, params) {
    return this.http.put<Response>(`${Environment.urls.API}/core/event/${id}/publication/`, params);
  }

  getEventCheckListFields(eventId: number): Observable<Array<CheckListField>> {
    return this.http.get<Response>(`${Environment.urls.API}/core/event/${eventId}/publication/checklist`)
      .pipe(map(v => plainToClass(CheckListField, v.data as Array<CheckListField>)));
  }

  createEventCheckList(params: Array<CheckListField>, eventId: number): Observable<Array<CheckListField>> {
    return this.http.post<Response>(`${Environment.urls.API}/core/event/${eventId}/publication/checklist`, params)
      .pipe(map(v => plainToClass(CheckListField, v.data as Array<CheckListField>)));
  }

  editEventCheckList(params: Array<CheckListField>, eventId: number): Observable<Array<CheckListField>> {
    return this.http.put<Response>(`${Environment.urls.API}/core/event/${eventId}/publication/checklist`, params)
      .pipe(map(v => plainToClass(CheckListField, v.data as Array<CheckListField>)));
  }

  deleteEventCheckList(checklistId: number, eventId: number): Observable<Array<CheckListField>> {
    return this.http.delete<Response>(`${Environment.urls.API}/core/event/${eventId}/publication/checklist/${checklistId}/`)
      .pipe(map(v => plainToClass(CheckListField, v.data as Array<CheckListField>)));
  }

  editSubmissionsAnswers(id: number, params): Observable<CheckListAnswer[]> {
    return this.http.post<Response>(`${Environment.urls.API}/core/event/${id}/publication/answers`, params).pipe(
      map(v => v.data as CheckListAnswer[])
    );
  }

  getSubmissionsAnswers(id: number, params): Observable<CheckListAnswer[]> {

    const options = { params: {}};
    if (params.category) {
      options.params['category'] = String(params.category);
    }

    return this.http.get<Response>(`${Environment.urls.API}/core/event/${id}/publication/answers`, options).pipe(
      map(v => v.data as CheckListAnswer[])
    );
  }

  toggleSubmissionAnswer(id: number, params): Observable<boolean> {
    return this.http.put<Response>(`${Environment.urls.API}/core/event/${id}/publication/answers`, params).pipe(
      map(v => v.data as boolean)
    );
  }

  editIeeeCopyrightControl(id: number, params) {
    return this.http.post<Response>(`${Environment.urls.API}/core/event/${id}/publication/copyright`, params);
  }

  getProceedingIdentificationInfo(id: number) {
    return this.http.get<Response>(`${Environment.urls.API}/core/event/${id}/publication/proceedings/identification`, {})
      .pipe(map(v => plainToClass(PublicationIdentificationInfo, v.data as PublicationIdentificationInfo)));
  }

  editProceedingIdentificationInfo(id: number, params) {
    return this.http.put<Response>(`${Environment.urls.API}/core/event/${id}/publication/proceedings/identification`, params);
  }

  getProceedingsContent(id: number): Observable<ProceedingsContent[]> {
    return this.http.get<Response>(`${Environment.urls.API}/core/event/${id}/publication/proceedings/content`, {}).pipe(
      map(v => v.data as ProceedingsContent[])
    );
  }
  
  editProceedingContent(id: number, params): Observable<ProceedingsContent[]> {
    return this.http.post<Response>(`${Environment.urls.API}/core/event/${id}/publication/proceedings/content`, params).pipe(
      map(v => v.data as ProceedingsContent[])
    );
  }

  getProceedingsFiles(id: number): Observable<File[]> {
    return this.http.get<Response>(`${Environment.urls.API}/core/event/${id}/publication/proceedings/file`, {}).pipe(
      map(v => v.data as File[])
    );
  }

  validProceedingsFile(file: File): FileUploadErrors {
    if (file.type != 'application/pdf') {
      return FileUploadErrors.INVALID_FORMAT;
    }
    if ((file.size / 1000000) > 300) {
      return FileUploadErrors.MAX_SIZE;
    }
    
    return FileUploadErrors.VALID;
  }

  submitProceedingsFile(id: number, params) {
    return this.http.post<Response>(`${Environment.urls.API}/core/event/${id}/publication/proceedings/file`, params, {
      observe: 'events',
      reportProgress: true
    });
  }

  deleteProceedingsFile(fileId: number, id: number) {
    return this.http.delete<Response>(`${Environment.urls.API}/core/event/${id}/publication/proceedings/file/${fileId}/`);
  }

  downloadProceedingsFile(fileId: number, id: number): Observable<any> {
    return this.http.get<Response>(`${Environment.urls.API}/core/event/${id}/publication/proceedings/downloadFile/${fileId}/`, {responseType: 'blob' as 'json'});
  }

  getProceedingsTrackFile(eventId: number): Observable<FileRules[]> {
    return this.http.get<Response>(`${Environment.urls.API}/core/event/${eventId}/publication/proceedings/trackFile`, {}).pipe(
      map(v => v.data as FileRules[])
    );
  }

  editProceedingsTrackFile(id: number, params): Observable<FileRules[]> {
    return this.http.post<Response>(`${Environment.urls.API}/core/event/${id}/publication/proceedings/trackFile`, params).pipe(
      map(v => v.data as FileRules[])
    );
  }

  getProceedingsViewingOptions(id: number): Observable<ProceedingsViewingOptions> {
    return this.http.get<Response>(`${Environment.urls.API}/core/event/${id}/publication/proceedings/indexes`).pipe(
      map(v => v.data as ProceedingsViewingOptions)
    );
  }

  editProceedingsViewingOptions(id: number, params): Observable<ProceedingsViewingOptions> {
    return this.http.post<Response>(`${Environment.urls.API}/core/event/${id}/publication/proceedings/indexes`, params).pipe(
      map(v => v.data as ProceedingsViewingOptions)
    );
  }

}
