import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Event } from 'src/app/model/event';
import { SelectOption } from 'src/app/model/select.option';
import { AdminService } from 'src/app/service/admin.service';
import { EventsService } from 'src/app/service/events.service';
import { StatisticsService } from 'src/app/service/statistics.service';

@Component({
  selector: 'app-submission-topic-statistics',
  templateUrl: './submission-topic-statistics.component.html',
  styleUrls: ['./submission-topic-statistics.component.scss']
})
export class SubmissionTopicStatisticsComponent implements OnInit, OnDestroy {
  @Input() event: Event;

  form: FormGroup;
  formTPCGroup: FormGroup;
  subscriptions: Subscription[] = [];

  haveTopics: boolean = false;

  filterControl = new FormControl('');
  tracks: Array<SelectOption>;
  tpcGroups: Array<SelectOption>;

  topicStatus : Array<any>;
  topicInterest : Array<any>;
  tableColsStatus = ['topic', 'pending', 'active', 'withdrawn', 'accepted', 'rejected', 'total', "total-valid"]
  tableColsInterest = ['topic', 'interested', 'neutral', 'not-interested']

  constructor(
    private statisticsService: StatisticsService,
    protected adminService: AdminService,
    private eventsService: EventsService,
    public fb: FormBuilder
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.adminService.progressBar.start();
      if (!this.event) {
        this.adminService.getEvent().subscribe(event => {
          this.event = event;
        }, () => {
        }, () => {
          this.initComponent();
          this.getTopicsBySubmissionStatus({});
          this.getTopicsByTPCInterests({});
        });
      } else {
        this.initComponent();
        this.getTopicsBySubmissionStatus({});
        this.getTopicsByTPCInterests({});
      }

      this.setFilters();
    });
  }

  initComponent(){
    if (this.event.topics.length > 0) {
      this.haveTopics = true;
    } else {
      this.haveTopics = false;
    }

    this.form = this.fb.group({
      tracks: []
    });

    this.formTPCGroup = this.fb.group({
      tpcGroups: []
    });

    this.tracks = this.event.tracks.map(t => {
      return new SelectOption(t.id, t.name);
    });

    this.adminService.eventsService.getEventTPCGroups(this.event.id).subscribe(tpcGroups => {
      this.tpcGroups = tpcGroups.map(g => 
        new SelectOption(g.id, g.name)
      );

      if (this.tpcGroups.length === 0) {
        this.formTPCGroup.controls['tpcGroups'].disable();
      }
    });
  }

  setFilters() {
    this.subscriptions.push(
      this.form.valueChanges
        .pipe(
          debounceTime(500),
          distinctUntilChanged()
        )
        .subscribe( value => {
          this.adminService.progressBar.start();
          const params = {}
          if (value.tracks && value.tracks.length > 0) {
            params['tracks'] = value.tracks
          }
          this.getTopicsBySubmissionStatus(params);
        }
      )
    );

    this.subscriptions.push(
      this.formTPCGroup.valueChanges
        .pipe(
          debounceTime(500),
          distinctUntilChanged()
        )
        .subscribe( value => {
          this.adminService.progressBar.start();
          const params = {}
          if (value.tpcGroups && value.tpcGroups.length > 0) {
            params['tpc_groups'] = value.tpcGroups
          }
          this.getTopicsByTPCInterests(params);
        }
      )
    );
  }

  getTopicsBySubmissionStatus(params){
    this.statisticsService.getTopicsBySubmissionStatus(params, this.event.id).subscribe( datasource => {
      this.topicStatus = datasource.items;
    }, () => {
      this.adminService.progressBar.stop();
    },() => {
      this.adminService.progressBar.stop();
    });
  }
  
  getTopicsByTPCInterests(params){
    this.statisticsService.getTopicsByTPCInterests(params, this.event.id).subscribe( datasource => {
      this.topicInterest = datasource.items;
    }, () => {
      this.adminService.progressBar.stop();
    },() => {
      this.adminService.progressBar.stop();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
