import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/service/admin.service';
import { DashboardDataset, Dashboard, DashboardDatasetSize, DashboardGraph } from 'src/app/model/dashboard';
import { plainToClass } from 'class-transformer';
import { Event } from 'src/app/model/event';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/component/confirmation-dialog/confirmation-dialog.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { InfoService } from 'src/app/service/info.service';
import { forkJoin, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/service/notification.service';

@Component({
  selector: 'app-dashboard-settings',
  templateUrl: './dashboard-settings.component.html',
  styleUrls: ['./dashboard-settings.component.scss']
})
export class DashboardSettingsComponent implements OnInit {
  event: Event;

  datasetList: DashboardDataset[];
  datasetMenuOpen = false;
  datasetMenu: { category: string, sets: DashboardDataset[] }[];

  currentDashboard: number;
  userDashboards: Array<Dashboard>;
  dashboardsToDelete: Array<number> = [];

  form: FormArray;

  datasetSizeOptions = DashboardDatasetSize.toSelectable();

  constructor(
    private infoService: InfoService,
    private adminService: AdminService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.event = this.adminService.selectedEvent;
      if (!this.event) {
        this.adminService.getEvent().subscribe(event => {
          this.event = event;
          this.getUserDashboards();
          this.getDatasetList();
        });
      } else {
        this.getUserDashboards();
        this.getDatasetList();
      }
    });
  }

  createDashboardControl(d): FormGroup {
    return this.fb.group({
      id: d.id,
      title: d.title,
      principal: d.principal,
      graphPosition: this.fb.array(d.graphPosition.map(f => this.fb.group(f)))
    });
  }

  getUserDashboards() {
    this.adminService.getEventDashboards().subscribe(dashboards => {
      this.userDashboards = dashboards;
      this.currentDashboard = this.userDashboards.findIndex(d => d.principal);

      this.form = this.fb.array(
        this.userDashboards.map(d => this.createDashboardControl(d))
      );
    });
  }

  getDatasetList() {
    this.datasetList = [];
    this.adminService.getAllDatasets().subscribe(datasets => {
      this.datasetList = datasets;

      this.datasetMenu = [];
      this.datasetList.forEach(set => {
        const found = this.datasetMenu.find(item => item.category === set.category);
        if (found) {
          found.sets.push(set);
        } else {
          this.datasetMenu.push({
            category: set.category,
            sets: [set]
          });
        }
      });
    });
  }

  get selectedDashboard(): Dashboard {
    return <Dashboard>this.form.controls[this.currentDashboard].value;
  }

  addSetToCurrentDashboard(set: DashboardDataset) {
    this.translateService.get(`dataset.${set.category}.${set.datasetName}`).subscribe(title => {
      const graphPosition = plainToClass(DashboardGraph, {
        dataset: set,
        size: 'MEDIUM',
        title
      });

      (<FormArray>(<FormArray>this.form.controls[this.currentDashboard]).controls['graphPosition']).push(this.fb.group(graphPosition));
    });
  }

  addNewDashboard() {
    const board = plainToClass(Dashboard, {
      title: `Dashboard ${this.userDashboards.length + 1}`,
      principal: false,
      graphPosition: []
    } as Dashboard);

    this.form.push(this.createDashboardControl(board));
  }

  deleteDashboard(index: number) {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'admin.event.dashboard.messages.confirm-dashboard-deletion',
        content: ''
      }
    }).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        const dashboard = (<Dashboard>this.form.controls[index].value);
        if (dashboard.id) {
          this.dashboardsToDelete.push(dashboard.id);
        }

        // Delete form control of specified dashboard
        this.form.removeAt(index);
      }
    });
  }

  deleteSet(graphControls: FormArray, setIndex: number) {
    // Delete form control of specified set of specified dashboard
    graphControls.removeAt(setIndex);
  }

  drop(event: CdkDragDrop<any>, graphPositionControl: FormArray) {
    moveItemInArray(graphPositionControl.controls, event.previousIndex, event.currentIndex);
    moveItemInArray(graphPositionControl.value, event.previousIndex, event.currentIndex);
  }

  getFormToSave(): Array<Dashboard> {
    // Create deep clone of form value to not edit form.
    const formValue = JSON.parse(JSON.stringify(this.form.value));
    formValue.forEach((d: Dashboard) => {
      if (d.graphPosition) {
        d.graphPosition?.forEach(g => {
          g.size = DashboardDatasetSize.valueToKey(g.size);
        });
      }
    });

    // Transform plain form values into Dashboard objects.
    const dashboards = plainToClass(Dashboard, formValue as Dashboard[]);

    // Set created dashboards as user dashboards for the current event.
    dashboards.forEach((dashboard, index) => {
      dashboard.order = index;
      dashboard.event = this.event.id;
      dashboard.user = this.infoService.user.id;

      dashboard.graphPosition.forEach((graph, order) => {
        // Update ordering of graph position.
        graph.order = order;

        // Tranform enum graph size values back into keys.
        graph.size = DashboardDatasetSize.valueToKey(graph.size);

        // Transform dataset object into used id only.
        graph.dataset = (<DashboardDataset>graph.dataset).id;
      });
    });

    return dashboards;
  }

  saveUserDashboards() {
    const newDashboards = this.getFormToSave();

    forkJoin([
      // Delete dashboards
      ...this.dashboardsToDelete.map(dashboard => this.adminService.deleteDashboard(dashboard)),

      // Update and create new dashboards
      ...newDashboards.map(dashboard => dashboard.id ?
        this.adminService.updateDashboard(dashboard.id, {
          title: dashboard.title,
          order: dashboard.order,
          principal: dashboard.principal
        }) :
        this.adminService.createDashboard(dashboard)
      )
    ]).pipe(switchMap((dashboards: Dashboard[]) => {
      dashboards.forEach(dashboard => {
        if (dashboard !== null) {
          // Updated or created a dashboard.
          // Update form with returned values.
          this.form.controls[dashboard.order].patchValue({
            id: dashboard.id,
            title: dashboard.title,
            principal: dashboard.principal,
          });

          newDashboards[dashboard.order].id = dashboard.id;
        }
      });

      // Update dashboard graphs from defined dashboards
      return forkJoin(newDashboards.map(d => this.adminService.editDashboardGraphs(d.id, d.graphPosition)));
    })).subscribe(createdGraphs => {
      // Redirect to dashboards page
      window.location.pathname = `/admin/${this.event.id}/`;
      this.notificationService.notify('admin.event.dashboard.messages.dashboards-updated', { params: {
        event: this.event.id
      }});
    });
  }

  changedPrincipal(dashboardIndex: number) {
    // Set any other principal dashboard to principal = false
    // If all are false, default is first dashboard.

    this.form.controls.forEach((control, index) => {
      if (index !== dashboardIndex) {
        control.patchValue({ principal: false });
      }
    });
  }
}
